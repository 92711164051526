function signup({ email = "", name = "", marketingSource = "" }) {
  return window
    .fetch("/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, name, marketingSource }),
      credentials: "same-origin",
    })
    .catch((e) => {
      console.log(e);
    })
    .then(() => {
      const searchParams = new URLSearchParams({
        email,
        name,
      });
      window.location.href = `https://calendly.com/prelim-demos/prelim-demo?${searchParams.toString()}`;
    });
}

module.exports = {
  signup,
};
