import React from "react";

const Svg = (props) => (
  <svg
    width="23"
    height="31"
    viewBox="0 0 23 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.584 20.0625V17.5625L12.9248 11.3125V4.4375C12.9248 3.4 12.2834 2.5625 11.4888 2.5625C10.6941 2.5625 10.0527 3.4 10.0527 4.4375V11.3125L2.39355 17.5625V20.0625L10.0527 16.9375V23.8125L8.1379 25.6875V27.5625L11.4888 26.3125L14.8396 27.5625V25.6875L12.9248 23.8125V16.9375L20.584 20.0625Z"
      fill="#6578FF"
    />
  </svg>
);

export default Svg;
