import React from "react";

const Svg = (props) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3048 3.3335L10.0479 4.5085L15.0218 9.16683H4.17383V10.8335H15.0218L10.0479 15.4918L11.3048 16.6668L18.4357 10.0002L11.3048 3.3335Z"
      fill="#6578FF"
    />
  </svg>
);

export default Svg;
