import React from "react";

const Svg = (props) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.8981 5.89L18.8481 1.52C18.6281 0.62 17.8481 0 16.9381 0H3.04805C2.14805 0 1.35805 0.63 1.14805 1.52L0.0980535 5.89C-0.141947 6.91 0.0780536 7.95 0.718054 8.77C0.798054 8.88 0.908054 8.96 0.998054 9.06V16C0.998054 17.1 1.89805 18 2.99805 18H16.9981C18.0981 18 18.9981 17.1 18.9981 16V9.06C19.0881 8.97 19.1981 8.88 19.2781 8.78C19.9181 7.96 20.1481 6.91 19.8981 5.89ZM16.9081 1.99L17.9581 6.36C18.0581 6.78 17.9681 7.2 17.7081 7.53C17.5681 7.71 17.2681 8 16.7681 8C16.1581 8 15.6281 7.51 15.5581 6.86L14.9781 2L16.9081 1.99ZM10.9981 2H12.9581L13.4981 6.52C13.5481 6.91 13.4281 7.3 13.1681 7.59C12.9481 7.85 12.6281 8 12.2181 8C11.5481 8 10.9981 7.41 10.9981 6.69V2ZM6.48805 6.52L7.03805 2H8.99805V6.69C8.99805 7.41 8.44805 8 7.70805 8C7.36805 8 7.05805 7.85 6.81805 7.59C6.56805 7.3 6.44805 6.91 6.48805 6.52ZM2.03805 6.36L3.04805 2H5.01805L4.43805 6.86C4.35805 7.51 3.83805 8 3.22805 8C2.73805 8 2.42805 7.71 2.29805 7.53C2.02805 7.21 1.93805 6.78 2.03805 6.36ZM2.99805 16V9.97C3.07805 9.98 3.14805 10 3.22805 10C4.09805 10 4.88805 9.64 5.46805 9.05C6.06805 9.65 6.86805 10 7.77805 10C8.64805 10 9.42805 9.64 10.0081 9.07C10.5981 9.64 11.3981 10 12.2981 10C13.1381 10 13.9381 9.65 14.5381 9.05C15.1181 9.64 15.9081 10 16.7781 10C16.8581 10 16.9281 9.98 17.0081 9.97V16H2.99805Z"
      fill="white"
    />
  </svg>
);

export default Svg;
