import React from "react";

const Svg = (props) => (
  <svg
    width="24"
    height="31"
    viewBox="0 0 24 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4822 15.0625H18.4271L11.6202 2.5625L4.86201 15.0625H6.7582L2.96582 22.5625H9.69486V27.5625H13.5359V22.5625H20.3719L16.4822 15.0625ZM6.55399 20.0625L10.3464 12.5625H8.58632L11.6299 6.9375L14.693 12.5625H12.8454L16.7351 20.0625H6.55399Z"
      fill="#6578FF"
    />
  </svg>
);

export default Svg;
