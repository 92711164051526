import React from "react";
import HelmetHeader from "./helmetHeader";
import "../css/news.css";
import Header from "./header";
import Footer from "./footer";
import CallToAction from "./call_to_action";
import SvgNewsBubble from "./svg_news_bubble";
import contentfulActions from "../actions/contentful";
import _ from "lodash";
import dateUtils from "../utils/date";

function Hero({ entries }) {
  var article =
    _.first(_.orderBy(entries.items, "fields.publishDate", "desc")) || {};
  var articleTitle = _.get(article, "fields.title");
  var articleFeatureImageUrl = _.get(
    _.find(_.get(entries, "includes.Asset"), {
      sys: { id: _.get(article, "fields.featureImage.sys.id") },
    }),
    "fields.file.url"
  );

  return (
    <div className="news-hero">
      <SvgNewsBubble className="news-hero-bubble" />
      <div className="news-hero-content">
        <div className="news-hero-article">
          <div className="news-hero-content-lhs">
            <div className="news-hero-subtitle">Featured Story</div>
            <div className="news-hero-title">{articleTitle}</div>
            <a
              href={article?.fields?.url}
              target="_blank"
              className="news-hero-button"
              rel="noopener noreferrer"
            >
              Read more
            </a>
          </div>
          <div className="news-hero-content-rhs">
            <img
              className="news-hero-image"
              src={articleFeatureImageUrl + "?fit=thumb&f=top_left&h=207&w=368"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Articles({ entries }) {
  return (
    <div className="news-articles">
      <div className="news-articles-content">
        <div className="news-articles-title">Press</div>
        <div className="news-articles-list">
          {_.map(
            _.orderBy(entries.items, "fields.publishDate", "desc"),
            (entry) => {
              var articleFeatureImageUrl = _.get(
                _.find(_.get(entries, "includes.Asset"), {
                  sys: { id: entry.fields.featureImage.sys.id },
                }),
                "fields.file.url"
              );
              return (
                <div className="news-articles-article">
                  <div className="news-articles-article-image-container">
                    <img
                      className="news-articles-article-image"
                      src={
                        articleFeatureImageUrl +
                        "?fit=thumb&f=top_left&h=207&w=368"
                      }
                    />
                  </div>
                  <div className="news-articles-article-publication">
                    {entry.fields.publicationName}
                  </div>
                  <div className="news-articles-article-title">
                    {entry.fields.title}
                  </div>
                  <div className="news-articles-article-date">
                    {dateUtils.getDisplayDate(entry.fields.publishDate)}
                  </div>
                  <a
                    href={entry.fields.url}
                    className="news-articles-article-link"
                  >
                    Read more
                  </a>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

function News() {
  var [entries, setEntries] = React.useState({});
  React.useEffect(() => {
    contentfulActions
      .getEntries({ content_type: "newsArticle" })
      .then((res) => {
        setEntries(res);
      });
  }, []);
  var title =
    "Prelim News | Important milestones in banking and Prelim history.";
  var description =
    "Prelim News is a source of information on the company and customers";
  if (_.isEmpty(entries)) {
    return <></>;
  }
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero entries={entries} />
      <Articles entries={entries} />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default News;
