import React from "react";

const Svg = (props) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.8069 16.3125H9.75769V12.5625H12.8069V9.4375H16.466V12.5625H19.5153V16.3125H16.466V19.4375H12.8069V16.3125ZM14.6365 2.5625L4.87891 6.3125V13.925C4.87891 20.2375 9.03807 26.125 14.6365 27.5625C20.2349 26.125 24.3941 20.2375 24.3941 13.925V6.3125L14.6365 2.5625ZM21.9547 13.925C21.9547 18.925 18.8444 23.55 14.6365 24.9625C10.4285 23.55 7.3183 18.9375 7.3183 13.925V8.05L14.6365 5.2375L21.9547 8.05V13.925Z"
      fill="#6578FF"
    />
  </svg>
);

export default Svg;
