import React from "react";
import "../css/about.css";
import AboutChrisImg from "../img/about_chris.png";
import AboutCollageInformalImg from "../img/about_collage_informal.png";
import AboutHeangImg from "../img/about_heang_20240618.png";
import CallToAction from "./call_to_action";
import Footer from "./footer";
import Header from "./header";
import HelmetHeader from "./helmetHeader";
import SvgAboutBubble from "./svg_about_bubble";

function Hero() {
  return (
    <div className="about-hero">
      <SvgAboutBubble className="about-hero-bubble" />
      <div className="about-hero-content">
        <div className="about-hero-title">Build better banking</div>
      </div>
    </div>
  );
}

function Founders() {
  return (
    <div className="about-founders">
      <div className="about-founders-content">
        <div className="about-founders-title">Our Founders</div>
        <div className="about-founders-subtitle">Meet the founders</div>
        <div className="about-founders-founders">
          <div className="about-founders-founder">
            <div className="about-founders-founder-img">
              <img
                src={AboutHeangImg}
                className="about-founders-founder-img-inner"
              />
            </div>
            <div className="about-founders-founder-name">Heang Chan</div>
            <div className="about-founders-founder-description">
              Heang Chan is the co-founder and Chief Executive Officer of
              Prelim. Before founding Prelim, Heang was part of the product team
              at Blend, and prior to transitioning to the technology side of
              banking, spent over a decade working at Goldman Sachs, Wells Fargo
              and Bank of America. He completed a joint MBA/MA at Stanford
              University.
            </div>
          </div>
          <div className="about-founders-founder">
            <div className="about-founders-founder-img">
              <img
                src={AboutChrisImg}
                className="about-founders-founder-img-inner"
              />
            </div>
            <div className="about-founders-founder-name">Chris Blaser</div>
            <div className="about-founders-founder-description">
              Chris Blaser is the co-founder and Chief Technology Officer of
              Prelim. He pursued degrees in Math and English from UC Berkeley,
              where he spent a good amount of time in the driver’s seat for Cal
              Sol, the university’s solar vehicle team. A self-taught software
              engineer, he eventually made his way to the engineering team at
              Blend.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Team() {
  return (
    <div className="about-team">
      <div className="about-team-content">
        <div className="about-team-title">Our Team</div>
        <div className="about-team-subtitle">The people behind Prelim</div>
        <div className="about-team-description">
          {`We like morning runs (to get coffee), building things out of wood, cars, reading and writing poetry, fine dining, sailing when there’s an ocean nearby, and spending time with our families outside of this one. We do things with speed, obsess over our customers, and write a lot.`}
        </div>
        <div className="about-team-description">
          {`
When we get together, we cook breakfast together so we can share our visions for the company over homemade omelettes and croissants from Costco. We tend to get a little competitive when it comes to chess, and sometimes a mean tournament of blitz chess transpires.`}
        </div>
      </div>
    </div>
  );
}

function CollageInformal() {
  return (
    <div className="about-collage-informal">
      <img src={AboutCollageInformalImg} className="about-collage-img" />
    </div>
  );
}

function About() {
  var title = "DAO Platform - About | Prelim";
  var description =
    "Prelim is the #1 platform for financial institutions to work with customers digitally. Go digital with zero code using one white-labeled, all-in one platform that integrates with your current technology stack.";
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero />
      <Founders />
      <Team />
      <CollageInformal />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default About;
