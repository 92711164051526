function getDisplayDate(rawDate) {
  var date = new Date(rawDate);

  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var monthStr = month < 10 ? `0${month}` : month;
  var day = date.getDate();
  var dayStr = day < 10 ? `0${day}` : day;
  var hours = date.getHours();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  var minutes = date.getMinutes();
  var minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  return `${year}-${monthStr}-${dayStr} ${hours}:${minutesStr}${ampm}`;
}

function getFormattedDate(rawDate) {
  const date = new Date(rawDate);

  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

module.exports = {
  getDisplayDate,
  getFormattedDate,
};
