const ROOT_URL = "https://api.hsforms.com";
const PORTAL_ID = "22312120";

function submitToForm({ formId, form }) {
  return fetch(
    ROOT_URL + `/submissions/v3/integration/submit/${PORTAL_ID}/${formId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    }
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

module.exports = {
  submitToForm,
};
