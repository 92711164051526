import _ from "lodash";
import React from "react";
import "../css/banner.css";
import SvgClose from "./svg_close";
import SvgStore from "./svg_store.js";

function Banner() {
  const localStorageOpen = localStorage.getItem("isOpen");
  const defaultIsOpen = _.isNull(localStorageOpen) ? true : false;

  console.log({ defaultIsOpen });
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen);
  const copy = `Prelim is now LIVE on the Fiserv AppMarket →`;

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem("isOpen", false);
  };

  if (!isOpen) return null;
  return (
    <div className="banner-banner">
      <div />
      <div className="banner-banner-center">
        <SvgStore className="banner-banner-icon" />
        <a
          className="banner-banner-copy"
          href="https://appmarket.fiservapps.com/prelim.html"
          target="_blank"
          rel="noreferrer"
        >
          {copy}
        </a>
      </div>
      <SvgClose onClick={handleClose} className="banner-banner-close-icon" />
    </div>
  );
}

export default Banner;
