import React from "react";
import "../css/news_callout.css";
import contentfulActions from "../actions/contentful";
import _ from "lodash";
import dateUtils from "../utils/date";

function NewsCallout() {
  var [entries, setEntries] = React.useState({});
  React.useEffect(() => {
    contentfulActions.getEntries({ content_type: "blogPost" }).then((res) => {
      setEntries(res);
    });
  }, []);
  var top3Entries = _.take(
    _.orderBy(entries.items, "fields.publishDate", "desc"),
    3
  );
  return (
    <div className="news-callout">
      <div className="news-callout-content">
        <div className="news-callout-title">Read more</div>
        <div className="news-callout-list">
          {_.map(top3Entries, (entry) => {
            var articleFeatureImageUrl = _.get(
              _.find(_.get(entries, "includes.Asset"), {
                sys: { id: _.get(entry, "fields.featureImage.sys.id") },
              }),
              "fields.file.url"
            );
            var author = _.get(
              _.find(_.get(entries, "includes.Entry"), {
                sys: { id: _.get(entry, "fields.author.sys.id") },
              }),
              "fields.name"
            );
            var url = "/blog/" + _.get(entry, "fields.slug");
            return (
              <div
                className="news-callout-article"
                key={_.get(entry, "fields.slug")}
              >
                <div className="news-callout-article-image-container">
                  <img
                    className="news-callout-article-image"
                    src={
                      articleFeatureImageUrl +
                      "?fit=thumb&f=top_left&h=207&w=368"
                    }
                  />
                </div>
                <div className="news-callout-article-title">
                  {entry.fields.title}
                </div>
                <div className="news-callout-article-date">
                  {dateUtils.getDisplayDate(entry.fields.publishDate)}
                </div>
                <div className="news-callout-article-author">{author}</div>
                <a href={url} className="news-callout-article-link">
                  Read more
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default NewsCallout;
