import React from "react";

const Svg = (props) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5 2.5625H7.5C6.125 2.5625 5 3.6875 5 5.0625V25.0625C5 26.4375 6.125 27.5625 7.5 27.5625H22.5C23.875 27.5625 25 26.4375 25 25.0625V10.0625L17.5 2.5625ZM7.5 25.0625V5.0625H16.25V10.0625H22.5V25.0625H7.5ZM13.75 23.8125H16.25V22.5625H17.5C18.1875 22.5625 18.75 22 18.75 21.3125V17.5625C18.75 16.875 18.1875 16.3125 17.5 16.3125H13.75V15.0625H18.75V12.5625H16.25V11.3125H13.75V12.5625H12.5C11.8125 12.5625 11.25 13.125 11.25 13.8125V17.5625C11.25 18.25 11.8125 18.8125 12.5 18.8125H16.25V20.0625H11.25V22.5625H13.75V23.8125Z"
      fill="#6578FF"
    />
  </svg>
);

export default Svg;
