import React from "react";
import "../css/testimonial.css";
import SvgArrowForward from "./svg_arrow_forward.js";

function Testimonial({ logoImg, quote, name, title, quoteeImg, link }) {
  return (
    <div className="testimonial">
      <div className="testimonial-content">
        <div className="testimonial-lhs">
          <div className="testimonial-logo">
            <img src={logoImg} className="testimonial-logo-img" />
          </div>
        </div>
        <div className="testimonial-rhs">
          <div className="testimonial-quote">
            {'"'}
            {quote}
            {'"'}
          </div>
          <div className="testimonial-rhs-bottom">
            <div className="testimonial-quotee">
              <div className="testimonial-quotee-image-container">
                <img src={quoteeImg} className="testimonial-quotee-img" />
              </div>
              <div className="testimonial-quotee-container">
                <div className="testimonial-quotee-name">{name}</div>
                <div className="testimonial-quotee-title">{title}</div>
              </div>
            </div>
            {link && (
              <a href={link} className="testimonial-link">
                <div className="testimonial-link-copy">Read more</div>
                <SvgArrowForward />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
