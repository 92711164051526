import React from "react";

const Svg = (props) => (
  <svg
    width="1297"
    height="980"
    viewBox="0 0 1297 980"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_2097_5199)">
      <path
        d="M1275.79 695.059C1397.2 955.512 597.471 497.683 328.975 601.529L718.87 192.7C1159.41 -261.162 1141.91 407.839 1275.79 695.059Z"
        fill="url(#paint0_linear_2097_5199)"
      />
    </g>
    <g filter="url(#filter1_f_2097_5199)">
      <path
        d="M296.752 382.019C391.48 396.769 486.542 414.396 509.286 367.49L348.096 239.826C329.995 246.852 313.81 307.799 296.752 382.019C172.165 362.62 48.1558 348.198 90.0002 492.11C199.935 870.201 251.423 579.247 296.752 382.019Z"
        fill="url(#paint1_linear_2097_5199)"
      />
    </g>
    <g filter="url(#filter2_f_2097_5199)">
      <path
        d="M740.904 659.612C399.975 735.158 132.301 744.422 4 569.582L1127.37 322C1156.1 399.308 1081.83 584.065 740.904 659.612Z"
        fill="url(#paint2_linear_2097_5199)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2097_5199"
        x="324.975"
        y="35.1479"
        width="967.271"
        height="745.215"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2097_5199"
        />
      </filter>
      <filter
        id="filter1_f_2097_5199"
        x="78.7148"
        y="236.826"
        width="433.572"
        height="442.128"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1.5"
          result="effect1_foregroundBlur_2097_5199"
        />
      </filter>
      <filter
        id="filter2_f_2097_5199"
        x="0"
        y="318"
        width="1137"
        height="397"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2097_5199"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2097_5199"
        x1="1099.41"
        y1="-34.1617"
        x2="1085.97"
        y2="406.418"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#FF9789" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2097_5199"
        x1="4"
        y1="661.5"
        x2="226.606"
        y2="193.016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2037DC" />
        <stop offset="0.843002" stopColor="#2037DC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2097_5199"
        x1="747.694"
        y1="781.935"
        x2="668.29"
        y2="438.488"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#E5FF45" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
