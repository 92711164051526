import _ from "lodash";
import React from "react";
import contentfulActions from "../actions/contentful";
import "../css/business_account_opening.css";
import AnalysisPreview from "../img/analysis_preview.png";
import DesktopBankerTable from "../img/desktop_banker_table.png";
import DesktopMCBNYStart from "../img/desktop_mcbny_start.png";
import LandingVideoThumbnail from "../img/landing_vid_thumbnail.jpg";
import LogoPacificWestern from "../img/logo_pacific_western.png";
import MobileMCBNYStart from "../img/mobile_mcbny_start.png";
import MobileTDBComplete from "../img/mobile_tdb_complete.png";
import MobileTDBStart from "../img/mobile_tdb_start.png";
import ProfileSusanTang from "../img/profile_susan_tang.png";
import TabletMCBNYStart from "../img/tablet_mcbny_start.png";
import CallToAction from "./call_to_action";
import Footer from "./footer";
import Header from "./header";
import HelmetHeader from "./helmetHeader";
import Lightbox from "./lightbox";
import NewsCallout from "./news_callout";
import SvgBusinessAccountOpeningBubble from "./svg_business_account_opening_bubble";
import SvgPlayArrow from "./svg_play_arrow";
import Testimonial from "./testimonial";

function Customers() {
  var [entries, setEntries] = React.useState({});
  React.useEffect(() => {
    contentfulActions.getEntries({ content_type: "customer" }).then((res) => {
      setEntries(res);
    });
  }, []);

  const orderedEntries = _.orderBy(entries.items, "fields.order");

  const tripledEntries = _.concat(
    orderedEntries,
    orderedEntries,
    orderedEntries
  );

  return (
    <div className="business-account-opening-customers">
      <div className="business-account-opening-customers-content">
        <div className="business-account-opening-customers-list">
          {_.map(tripledEntries, (entry) => {
            const imageUrl = _.get(
              _.find(_.get(entries, "includes.Asset"), {
                sys: { id: _.get(entry, "fields.image.sys.id") },
              }),
              "fields.file.url"
            );

            return (
              <div
                className="business-account-opening-customer"
                key={entry.sys.id}
              >
                <div className="business-account-opening-customers-image">
                  <img
                    src={imageUrl}
                    className="business-account-opening-customers-logo-img"
                    alt={entry.fields.name}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function EndToEnd() {
  return (
    <div className="business-account-opening-end-to-end">
      <div className="business-account-opening-end-to-end-content">
        <div className="business-account-opening-end-to-end-title">
          End to end account opening
        </div>
      </div>
      <div className="business-account-opening-end-to-end-content">
        <div className="business-account-opening-end-to-end-content-bottom">
          <div className="business-account-opening-end-to-end-lhs">
            <div className="business-account-opening-end-to-end-copy">
              <div className="business-account-opening-end-to-end-category">
                From digital application to core
              </div>
              <div className="business-account-opening-end-to-end-description-container">
                <div className="business-account-opening-end-to-end-description">
                  Fast-to-market digital customer acquisition journeys through a
                  pre-built solution that runs on the Prelim platform. The
                  extensibility of the platform provides agility to respond to
                  market changes, stay competitive, and connect to the latest
                  innovations in account opening experiences.
                </div>
              </div>
            </div>
          </div>
          <div className="business-account-opening-end-to-end-rhs">
            <div className="business-account-opening-end-to-end-images">
              <img
                src={MobileTDBStart}
                className="business-account-opening-end-to-end-image-left"
              />
              <img
                src={MobileTDBComplete}
                className="business-account-opening-end-to-end-image-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WorkWith() {
  return (
    <div className="business-account-opening-work-with">
      <div className="business-account-opening-work-with-content">
        <div className="business-account-opening-work-with-title">
          Work with customers in real time
        </div>
      </div>
      <div className="business-account-opening-work-with-content">
        <div className="business-account-opening-work-with-content-bottom">
          <div className="business-account-opening-work-with-lhs">
            <img
              src={DesktopBankerTable}
              className="business-account-opening-work-with-image"
            />
          </div>
          <div className="business-account-opening-work-with-rhs">
            <div className="business-account-opening-work-with-content-inner">
              <div className="business-account-opening-work-with-category">
                Banker portal
              </div>
              <div className="business-account-opening-work-with-description-container">
                <div className="business-account-opening-work-with-description">
                  {`Prelim’s launchpad enables the bank to use an out of the box customer application, enabling the bank to go live in weeks, not years. Prelim’s platform tailors customer journeys to the brand and image of the bank.`}
                  <br />
                  <br />
                  {`Prelim’s builder platform enables the changing of questions, sections, and visual design without sacrificing speed to the market.`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Omnichannel() {
  return (
    <div className="business-account-opening-omnichannel">
      <div className="business-account-opening-omnichannel-content">
        <div className="business-account-opening-omnichannel-title">
          Parallel, separate, personal experiences for each business officer
        </div>
      </div>
      <div className="business-account-opening-omnichannel-content-container">
        <div className="business-account-opening-omnichannel-content">
          <div className="business-account-opening-omnichannel-content-top">
            <div className="business-account-opening-omnichannel-lhs">
              <img
                src={TabletMCBNYStart}
                className="business-account-opening-omnichannel-tablet"
              />
            </div>
            <div className="business-account-opening-omnichannel-rhs">
              <div className="business-account-opening-omnichannel-copy">
                <div className="business-account-opening-omnichannel-category">
                  Onboarding in any channel
                </div>
                <div className="business-account-opening-omnichannel-description-container">
                  <div className="business-account-opening-omnichannel-description">
                    The Prelim DAO business customer journey solves the complex
                    challenges of the deposit account opening process, including
                    handling the experiences for every business officer
                    individually and separate.
                    <br />
                    <br />
                    Through pre-integrated connections to proven FinTech
                    providers, Launchpad DAO addresses the critical issues of
                    fraudscreening, ID verification, risk mitigation, data
                    pre-fill, funding, and core account creation.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="business-account-opening-omnichannel-content-bottom-container">
            <div className="business-account-opening-omnichannel-content-bottom">
              <div>
                <img
                  src={DesktopMCBNYStart}
                  className="business-account-opening-omnichannel-desktop"
                />
              </div>
              <div>
                <img
                  src={MobileMCBNYStart}
                  className="business-account-opening-omnichannel-mobile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Hero() {
  var [email, setEmail] = React.useState("");
  var [modalIsOpen, setModalIsOpen] = React.useState(false);
  function handleChange(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    window.location.href = `/signup?email=${email}`;
  }

  const handleClickVideo = () => {
    setModalIsOpen(true);
  };

  const videoUrl = "https://player.vimeo.com/video/893464397?h=725dde136b";
  return (
    <div className="business-account-opening-hero">
      <Lightbox
        videoUrl={videoUrl}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        closeModal={() => setModalIsOpen(false)}
      />
      <SvgBusinessAccountOpeningBubble className="business-account-opening-hero-bubble" />
      <div className="business-account-opening-hero-content">
        <div className="business-account-opening-hero-title">
          Business Deposit Account Opening
        </div>
        <div className="business-account-opening-hero-container">
          <div className="business-account-opening-hero-container-lhs">
            <div className="business-account-opening-hero-copy-container">
              <div className="business-account-opening-hero-subtitle">
                It starts with Prelim
              </div>
              <div className="business-account-opening-hero-description">
                Increase speed-to-market, address compliance and risk, and
                improve the experience for businesses.
              </div>
            </div>
            <div className="business-account-opening-hero-form-container">
              <form
                className="business-account-opening-hero-form"
                onSubmit={handleSubmit}
              >
                <input
                  className="business-account-opening-hero-email-input"
                  placeholder="Enter your work email"
                  type="text"
                  onChange={handleChange}
                />
                <button
                  className="business-account-opening-hero-button"
                  type="Submit"
                  onClick={handleSubmit}
                >
                  Talk to an expert
                </button>
              </form>
            </div>
          </div>
          <div
            className="business-account-opening-hero-video"
            onClick={handleClickVideo}
          >
            <img
              className="business-account-opening-hero-video-thumbnail"
              src={LandingVideoThumbnail}
            />
            <div className="business-account-opening-hero-video-button" />
            <div className="business-account-opening-hero-video-button-inner" />
            <SvgPlayArrow className="business-account-opening-hero-button-image" />
          </div>
        </div>
      </div>
    </div>
  );
}

function Analysis() {
  return (
    <div className="business-account-opening-analysis">
      <div className="business-account-opening-analysis-content">
        <div className="business-account-opening-analysis-title">
          Analyze and discover improvements
        </div>
      </div>
      <div className="business-account-opening-analysis-content">
        <div className="business-account-opening-analysis-content-bottom">
          <div className="business-account-opening-analysis-content-lhs">
            <div className="business-account-opening-analysis-image-container">
              <img
                src={AnalysisPreview}
                className="business-account-opening-analysis-image"
              />
            </div>
          </div>
          <div className="business-account-opening-analysis-content-rhs">
            <div className="business-account-opening-analysis-content-copy">
              <div className="business-account-opening-analysis-content-title">
                Insights & Discovery Dashboard
              </div>
              <div className="business-account-opening-analysis-content-description">
                Detailed behavioral analytics from Prelim Insights help you
                understand how customers interact with the journey, far beyond
                anything offered in other packaged solutions. In addition,
                receive an annual CX best practice review, specific to your
                application.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PWBTestimonial() {
  const quote =
    "Prelim powers the next generation of digital transformation and growth at our bank.";
  const name = "Susan Tang";
  const title = "EVP, Head of Treasury Sales & Product at Pacific Western Bank";
  return (
    <Testimonial
      quote={quote}
      logoImg={LogoPacificWestern}
      name={name}
      title={title}
      quoteeImg={ProfileSusanTang}
    />
  );
}

function BusinessAccountOpening() {
  var title = "Prelim: The platform for business deposit account opening.";
  var description =
    "Prelim is the #1 platform for financial institutions to work with customers digitally. Go digital with zero code using one white-labeled, all-in one platform that integrates with your current technology stack.";
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero />
      <Customers />
      <EndToEnd />
      <WorkWith />
      <Omnichannel />
      <PWBTestimonial />
      <Analysis />
      <NewsCallout />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default BusinessAccountOpening;
