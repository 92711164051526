import _ from "lodash";
import React from "react";
import signupActions from "../actions/signup";
import "../css/signup.css";
import Footer from "./footer";
import Header from "./header";
import HelmetHeader from "./helmetHeader";
import SvgSignupBubble from "./svg_signup_bubble";
const segment = require("../external/segment");
import { useLocation } from "react-router-dom";

function Hero() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let email = "";
  if (params.has("email")) email = params.get("email");

  const [info, setInfo] = React.useState({ email });
  const [error, setError] = React.useState({});

  function handleChange(e) {
    var update = Object.assign({}, info);
    update[e.target.name] = e.target.value;

    setInfo(update);
  }

  function handleClick(e) {
    e.preventDefault();
    const errors = getErrors();
    if (!_.isEmpty(errors)) {
      setError(errors);
      return;
    }
    info.name = `${info.first_name} ${info.last_name}`;
    segment.track("Signup Clicked", info);

    return signupActions.signup(info);
  }

  function getErrors() {
    const errors = {};
    if (!info.first_name) errors.first_name = true;
    if (!info.last_name) errors.last_name = true;
    if (!info.email) errors.email = true;
    if (!info.marketingSource) errors.marketing = true;
    return errors;
  }

  return (
    <div className="signup-hero">
      <SvgSignupBubble className="signup-hero-bubble" />
      <div className="signup-hero-content">
        <div className="signup-hero-content-inner">
          <form className="signup-hero-form" autoComplete="on">
            <div className="signup-hero-content-title">Sign up for Prelim</div>
            <div className="signup-hero-form-label">First Name</div>
            <input
              className={
                error.first_name
                  ? "signup-hero-input-error"
                  : "signup-hero-input"
              }
              name="first_name"
              onChange={handleChange}
              value={info.first_name}
              placeholder="First Name"
            />
            <div className="signup-hero-form-label">Last Name</div>
            <input
              className={
                error.last_name
                  ? "signup-hero-input-error"
                  : "signup-hero-input"
              }
              name="last_name"
              onChange={handleChange}
              value={info.last_name}
              placeholder="Last Name"
            />
            <div className="signup-hero-form-label">Email</div>
            <input
              className={
                error.email ? "signup-hero-input-error" : "signup-hero-input"
              }
              name="email"
              onChange={handleChange}
              value={info.email}
              placeholder="Email"
            />
            <div className="signup-hero-form-label">
              How did you hear about us?
            </div>
            <select
              className={
                error.marketing
                  ? "signup-hero-input-error"
                  : "signup-hero-input"
              }
              name="marketingSource"
              value={info.marketingSource}
              onChange={handleChange}
              required
            >
              <option value="">Select an option</option>
              <option value="Social Media">Social Media</option>
              <option value="Press">Press</option>
              <option value="Event Or Conference">Event or Conference</option>
              <option value="Email">Email</option>
              <option value="Word of Mouth">Word of Mouth</option>
              <option value="Other">Other</option>
            </select>
            <button className="signup-hero-button" onClick={handleClick}>
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

function Landing() {
  var title = "Signup for Prelim | DAO Platform & Customer Journey Insights";
  var description =
    "Prelim is the #1 platform for financial institutions to work with customers digitally. Go digital with zero code using one white-labeled, all-in one platform that integrates with your current technology stack.";
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero />
      <Footer />
    </div>
  );
}

export default Landing;
