import React from "react";

const Svg = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="arrow_forward_24dp_FILL0_wght400_GRAD0_opsz24 1">
      <path
        id="Vector"
        d="M16.175 13H4V11H16.175L10.575 5.4L12 4L20 12L12 20L10.575 18.6L16.175 13Z"
        fill="#212121"
      />
    </g>
  </svg>
);

export default Svg;
