import React from "react";

const Svg = (props) => (
  <svg
    width="102"
    height="20"
    viewBox="0 0 102 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1987_26672)">
      <path
        d="M19.9283 3.68408H11.2754V9.47356H19.9283V3.68408Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.80582 2.65723C5.34535 2.65723 4.93876 2.95862 4.80333 3.40028L0.154517 18.5635C-0.0530093 19.2404 0.451312 19.9257 1.15698 19.9257H11.6685C12.1289 19.9257 12.5355 19.6243 12.6709 19.1826L13.8331 15.3918L15.4695 13.0265L16.1521 7.85136H14.3059C14.3059 5.16502 13.1428 4.60546 12.5852 4.60538C13.6752 4.60538 14.5099 4.60617 15.1151 4.60678C15.9344 4.60757 16.3335 4.60796 16.3771 4.60538C16.6404 4.58983 16.883 4.64117 17.0985 4.74115L17.3198 4.01941C17.5273 3.34252 17.023 2.65723 16.3173 2.65723H5.80582Z"
        fill="#99A3F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0982 0.743068C10.2337 0.301392 10.6403 0 11.1007 0H21.6122C22.3179 0 22.8222 0.685297 22.6147 1.36219L17.9659 16.5254C17.8304 16.9671 17.4238 17.2685 16.9634 17.2685H6.45189C5.74623 17.2685 5.24192 16.5832 5.44943 15.9063L10.0982 0.743068ZM15.1502 12.6701H11.406V6.51397C11.406 5.05889 12.0515 4.61118 12.5851 4.61118H12.5986C12.5943 4.6111 12.59 4.61108 12.5857 4.61108C12.8177 4.61108 13.038 4.61113 13.2471 4.61118H16.2655C16.2655 4.61118 16.256 4.61118 16.2387 4.61268C16.3187 4.61237 16.3645 4.61187 16.3776 4.61108C18.2736 4.49916 19.1021 7.85705 16.3776 7.85705H15.1502V12.6701Z"
        fill="#6578FF"
      />
      <path
        d="M32.7759 3.41211V19.0213H30.1543V3.41211H32.7759ZM31.7092 3.41211H34.8424C35.9825 3.41211 36.9676 3.60945 37.7975 4.00316C38.6271 4.39737 39.2711 4.95853 39.7308 5.68669C40.1899 6.41535 40.4196 7.27769 40.4196 8.27321C40.4196 9.26924 40.1899 10.1276 39.7308 10.8485C39.2716 11.5698 38.6271 12.1275 37.7975 12.5207C36.9676 12.9149 35.983 13.1118 34.8424 13.1118H31.7092V10.7926H34.8424C35.4491 10.7926 35.9751 10.7001 36.4198 10.514C36.8643 10.3284 37.212 10.0459 37.4639 9.66685C37.7157 9.28785 37.8415 8.82314 37.8415 8.27321C37.8415 7.70811 37.7157 7.23998 37.4639 6.86829C37.2117 6.49714 36.8643 6.21458 36.4198 6.02116C35.9751 5.82821 35.4491 5.73127 34.8424 5.73127H31.7092V3.41211Z"
        fill="#6578FF"
      />
      <path
        d="M45.6177 3.41211V19.0213H42.9961V3.41211H45.6177ZM44.5513 3.41211H47.7948C48.9352 3.41211 49.9242 3.61287 50.7609 4.01393C51.5977 4.41548 52.2458 4.98058 52.7052 5.70874C53.1644 6.43737 53.3943 7.29187 53.3943 8.27321C53.3943 9.23985 53.1644 10.087 52.7052 10.8152C52.2461 11.5438 51.5977 12.1084 50.7609 12.51C49.9242 12.9115 48.9352 13.1123 47.7948 13.1123H44.5513V10.9268H47.6842C48.2912 10.9268 48.8172 10.8264 49.2616 10.6257C49.7061 10.4249 50.054 10.1276 50.3057 9.73393C50.5575 9.34024 50.6833 8.86819 50.6833 8.31777C50.6833 7.75269 50.5575 7.27719 50.3057 6.89035C50.0535 6.50398 49.7061 6.21016 49.2616 6.0094C48.8172 5.80864 48.2912 5.70824 47.6842 5.70824H44.5513V3.41211ZM49.039 11.5732L54.3273 19.0213H51.1498L46.2174 11.5732H49.039Z"
        fill="#6578FF"
      />
      <path
        d="M58.7706 3.41211V19.0213H56.1934V3.41211H58.7706ZM57.8154 3.41211H65.6586V5.73127H57.8154V3.41211ZM57.8154 9.5219H65.2142V11.7965H57.8154V9.5219ZM57.8154 16.7021H65.6586V19.0213H57.8154V16.7021Z"
        fill="#6578FF"
      />
      <path
        d="M71.503 3.41211V16.6576H77.6796V19.0213H68.8809V3.41211H71.503Z"
        fill="#6578FF"
      />
      <path
        d="M82.2103 3.41211V19.0213H79.5664V3.41211H82.2103Z"
        fill="#6578FF"
      />
      <path
        d="M87.5001 19.0211H84.7891L86.9224 2.63135L93.2991 13.2232L99.6981 2.63135L101.831 19.0211H99.1205L98.0538 9.52169L93.2991 17.5716L88.5667 9.52169L87.5001 19.0211Z"
        fill="#6578FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_1987_26672">
        <rect width="102" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Svg;
