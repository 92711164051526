import React from "react";
import HelmetHeader from "./helmetHeader";
import "../css/integrations.css";
import Header from "./header";
import Footer from "./footer";
import CallToAction from "./call_to_action";
import SvgIntegrationsBubble from "./svg_integrations_bubble";
import contentfulActions from "../actions/contentful";
import _ from "lodash";

function Apps() {
  var [entries, setEntries] = React.useState({});
  React.useEffect(() => {
    contentfulActions
      .getEntries({ content_type: "integration" })
      .then((res) => {
        setEntries(res);
      });
  }, []);
  return (
    <div className="integrations-apps">
      <div className="integrations-apps-content">
        <div className="integrations-apps-list-container">
          <div className="integrations-apps-title">Prelim partners</div>
          <div className="integrations-apps-list">
            {_.map(
              _.orderBy(
                entries.items,
                (entry) => entry.fields.name.toLowerCase(),
                "asc"
              ),
              (entry) => {
                var logoUrl = _.get(
                  _.find(_.get(entries, "includes.Asset"), {
                    sys: { id: _.get(entry, "fields.logo.sys.id") },
                  }),
                  "fields.file.url"
                );
                return (
                  <div className="integrations-app">
                    <div className="integrations-app-image-container">
                      <img className="integrations-app-image" src={logoUrl} />
                    </div>
                    <div className="integrations-app-container">
                      <div className="integrations-app-container-inner">
                        <div className="integrations-app-title">
                          {entry.fields.name}
                        </div>
                        <div className="integrations-app-description">
                          {entry.fields.description}
                        </div>
                      </div>
                      <a
                        href={entry.fields.link}
                        className="integrations-app-link"
                      >
                        {entry.fields.link}
                      </a>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function Hero() {
  return (
    <div className="integrations-hero">
      <SvgIntegrationsBubble className="integrations-hero-bubble" />
      <div className="integrations-hero-content">
        <div className="integrations-hero-title">
          Plug and play with other systems
        </div>
      </div>
    </div>
  );
}

function Landing() {
  var title = "DAO Platform - Integrations | Prelim";
  var description =
    "Prelim's partners help deliver the #1 platform for financial institutions to work with customers digitally. Go digital with zero code using one white-labeled, all-in one platform that integrates with your current technology stack.";
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero />
      <Apps />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default Landing;
