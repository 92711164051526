import React from "react";
import "../css/header.css";
import SvgPrelimLogo from "./svg_prelim_logo";

function MobileMenu() {
  //const { setShowMobileMenu } = props;
  return (
    <ul className="header-mobile-nav-menu">
      <li className="header-mobile-nav-menu-item">
        <a href="/" className="header-logo">
          <SvgPrelimLogo />
        </a>
      </li>
      <li className="header-mobile-nav-menu-item">
        <button
          className="header-button"
          onClick={() => (window.location.href = "/signup")}
        >
          Book a Demo
        </button>
      </li>
      <li className="header-mobile-nav-menu-item">
        <div className="header-mobile-link">Solutions</div>
        <ul className="header-mobile-nav-submenu">
          <li className="header-nav-mobile-submenu-item">
            <a
              className="header-mobile-nav-submenu-link"
              href="/consumer-account-opening"
            >
              <div className="header-mobile-nav-submenu-text">
                Consumer Account Opening
              </div>
            </a>
          </li>
          <li className="header-mobile-nav-submenu-item">
            <a
              className="header-mobile-nav-submenu-link"
              href="/business-account-opening"
            >
              <div className="header-mobile-nav-submenu-text">
                Business Account Opening
              </div>
            </a>
          </li>
        </ul>
      </li>
      <li className="header-mobile-nav-menu-item">
        <div className="header-mobile-link">Platform</div>
        <ul className="header-mobile-nav-submenu">
          <li className="header-mobile-nav-submenu-item">
            <a className="header-mobile-nav-submenu-link" href="/integrations">
              <div className="nav-mobile-submenu-text">Integrations</div>
            </a>
          </li>
          <li className="header-mobile-nav-submenu-item">
            <a
              className="header-mobile-nav-submenu-link"
              href="https://updates.prelim.com"
            >
              <div className="header-mobile-nav-submenu-text">Updates</div>
            </a>
          </li>
        </ul>
      </li>

      <li className="header-mobile-nav-menu-item">
        <div className="header-mobile-link">Academy</div>
        <ul className="header-mobile-nav-submenu">
          <li className="header-mobile-nav-submenu-item">
            <a
              className="header-mobile-nav-submenu-link"
              href="https://docs.prelim.com/reference/getting-started-with-your-api-1"
            >
              <div className="header-mobile-nav-submenu-text">
                API Documentation
              </div>
            </a>
          </li>
          <li className="header-mobile-nav-submenu-item">
            <a
              className="header-mobile-nav-submenu-link"
              href="https://docs.prelim.com"
            >
              <div className="header-mobile-nav-submenu-text">
                Learning Center
              </div>
            </a>
          </li>
        </ul>
      </li>

      <li className="header-mobile-nav-menu-item">
        <div className="header-mobile-link">Resources</div>
        <ul className="header-mobile-nav-submenu">
          <li className="header-mobile-nav-submenu-item">
            <a className="header-mobile-nav-submenu-link" href="/blog">
              <div className="header-mobile-nav-submenu-text">Blog</div>
            </a>
          </li>
          <li className="header-mobile-nav-submenu-item">
            <a
              className="header-mobile-nav-submenu-link"
              href="https://support.prelim.com"
            >
              <div className="nav-mobile-submenu-text">Support</div>
            </a>
          </li>
          <li className="header-mobile-nav-submenu-item">
            <a
              className="header-mobile-nav-submenu-link"
              href="https://features.prelim.com"
            >
              <div className="header-mobile-nav-submenu-text">Requests</div>
            </a>
          </li>
          <li className="header-mobile-nav-submenu-item">
            <a
              className="header-mobile-nav-submenu-link"
              href="https://status.prelim.com"
            >
              <div className="header-mobile-nav-submenu-text">Status</div>
            </a>
          </li>
        </ul>
      </li>
      <li className="header-mobile-nav-menu-item">
        <a className="header-mobile-link">Company</a>
        <ul className="header-mobile-nav-submenu">
          <li className="header-mobile-nav-submenu-item">
            <a className="header-mobile-nav-submenu-link" href="/about">
              <div className="header-mobile-nav-submenu-text">About</div>
            </a>
          </li>
          <li className="header-mobile-nav-submenu-item">
            <a className="header-mobile-nav-submenu-link" href="/careers">
              <div className="header-mobile-nav-submenu-text">Careers</div>
            </a>
          </li>
          <li className="header-mobile-nav-submenu-item">
            <a className="header-mobile-nav-submenu-link" href="/news">
              <div className="header-mobile-nav-submenu-text">Press</div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
}

function Header() {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  return (
    <div className="header-root">
      <div className="header-container">
        <a href="/" className="header-logo">
          <SvgPrelimLogo />
        </a>
        <nav className="header-mobile">
          <div className="header-hamburger-box">
            <button
              className="header-hamburger"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <span className="header-hamburger-bar" />
              <span className="header-hamburger-bar" />
              <span className="header-hamburger-bar" />
            </button>
          </div>
          {showMobileMenu && (
            <MobileMenu hideMobileMenu={() => setShowMobileMenu(false)} />
          )}
        </nav>
        <nav className="header-nav">
          <ul className="header-nav-menu">
            <li className="header-nav-menu-item">
              <div className="header-link">Solutions</div>
              <ul className="header-nav-submenu">
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="/consumer-account-opening"
                  >
                    <div className="header-nav-submenu-text">
                      Consumer Account Opening
                    </div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="/business-account-opening"
                  >
                    <div className="header-nav-submenu-text">
                      Business Account Opening
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li className="header-nav-menu-item">
              <div className="header-link">Platform</div>
              <ul className="header-nav-submenu">
                <li className="header-nav-submenu-item">
                  <a className="header-nav-submenu-link" href="/integrations">
                    <div className="header-nav-submenu-text">Integrations</div>
                  </a>
                </li>
                {/*
                <li className="header-nav-submenu-item">
                  <a className="header-nav-submenu-link" href="/security">
                    <div className="header-nav-submenu-text">Security</div>
                  </a>
                </li>
                */}
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="https://updates.prelim.com"
                  >
                    <div className="header-nav-submenu-text">Updates</div>
                  </a>
                </li>
              </ul>
            </li>

            <li className="header-nav-menu-item">
              <div className="header-link">Academy</div>
              <ul className="header-nav-submenu">
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="https://docs.prelim.com/reference/getting-started-with-your-api-1"
                  >
                    <div className="header-nav-submenu-text">
                      API Documentation
                    </div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="https://docs.prelim.com"
                  >
                    <div className="header-nav-submenu-text">
                      Learning Center
                    </div>
                  </a>
                </li>
                {/*
                <li className="header-nav-submenu-item">
                  <a className="header-nav-submenu-link" href="">
                    <div className="header-nav-submenu-text">
                      Community (Coming soon!)
                    </div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="/customer-stories"
                  >
                    <div className="header-nav-submenu-text">
                      Customer Stories
                    </div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a className="header-nav-submenu-link" href="/events">
                    <div className="header-nav-submenu-text">Events</div>
                  </a>
                </li>
                */}
              </ul>
            </li>
            <li className="header-nav-menu-item">
              <div className="header-link">Resources</div>
              <ul className="header-nav-submenu header-nav-submenu-small">
                <li className="header-nav-submenu-item">
                  <a className="header-nav-submenu-link" href="/blog">
                    <div className="header-nav-submenu-text">Blog</div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="https://support.prelim.com"
                  >
                    <div className="header-nav-submenu-text">Support</div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="https://features.prelim.com"
                  >
                    <div className="header-nav-submenu-text">Requests</div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a
                    className="header-nav-submenu-link"
                    href="https://status.prelim.com"
                  >
                    <div className="header-nav-submenu-text">Status</div>
                  </a>
                </li>
              </ul>
            </li>

            <li className="header-nav-menu-item">
              <div className="header-link">Company</div>
              <ul className="header-nav-submenu header-nav-submenu-small">
                <li className="header-nav-submenu-item">
                  <a className="header-nav-submenu-link" href="/company">
                    <div className="header-nav-submenu-text">About</div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a className="header-nav-submenu-link" href="/careers">
                    <div className="header-nav-submenu-text">Careers</div>
                  </a>
                </li>
                <li className="header-nav-submenu-item">
                  <a className="header-nav-submenu-link" href="/news">
                    <div className="header-nav-submenu-text">Press</div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <nav className="header-nav">
          <ul className="header-nav-menu">
            <li className="header-nav-menu-item">
              <div className="header-actions">
                <button
                  className="header-button"
                  onClick={() => (window.location.href = "/signup")}
                >
                  Book a Demo
                </button>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
