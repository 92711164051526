import React from "react";
import CallToAction from "./call_to_action";
import HelmetHeader from "./helmetHeader";
import Header from "./header";
import Footer from "./footer";
import SvgHealthAndSafety from "./svg_health_and_safety";
import SvgEscalatorWarning from "./svg_escalator_warning";
import SvgFlight from "./svg_flight";
import LeverActions from "../actions/lever";
import SvgPark from "./svg_park";
import SvgLocalMall from "./svg_local_mall";
import SvgRequestQuote from "./svg_request_quote";
import "../css/careers.css";
import SvgCareerBubble from "./svg_career_bubble";
import MissionFounders from "../img/careers_hero.png";

function Hero() {
  return (
    <div className="careers-hero">
      <SvgCareerBubble className="careers-hero-bubble" />
      <div className="careers-hero-content">
        <div className="careers-hero-title">Help build better banking</div>
      </div>
    </div>
  );
}

function Mission() {
  return (
    <div className="careers-mission">
      <div className="careers-mission-lhs">
        <img src={MissionFounders} className="careers-mission-image" />
      </div>
      <div className="careers-mission-rhs">
        <div className="careers-mission-copy">
          <div className="careers-mission-title">Our Mission</div>
          <div className="careers-mission-subtitle">
            Build better banking experiences
          </div>
          <div className="careers-mission-description">
            {`From having to fax documents, to waiting until the bank is open, individuals and businesses spend an insane amount of time trying to get the services they need. For too many individuals and businesses, their ability to access banking is limited by the archaic technology that banks use. And for the banks themselves, many desperately want to provide a better experience but are trapped by their tools. 

We believe banks need a better way: a better set of tools that will help them provide easier, faster, and frictionless access to their customers.`}
          </div>
        </div>
      </div>
    </div>
  );
}

function Values() {
  return (
    <div className="careers-values">
      <div className="careers-values-content">
        <div className="careers-values-title">Core Values</div>
        <div className="careers-values-subtitle">How we work</div>
        <div className="careers-values-list">
          <div className="careers-values-item">
            <div className="careers-values-item-title">
              <div className="careers-values-item-number">1</div>Customer
              Focused
            </div>
            <div className="careers-values-item-description">
              Prelimmers start with the customer (banks and their customers) and
              work backwards. We work vigorously to earn and keep our customers’
              trust, from the bank’s employees to the clients they serve.
              Although we pay attention to competitors, we obsess over
              customers, and make decisions based on the good of our customers.
            </div>
          </div>
          <div className="careers-values-item">
            <div className="careers-values-item-title">
              <div className="careers-values-item-number">2</div>Formidable
            </div>
            <div className="careers-values-item-description">
              {`We say what we're going to do, and then we do it. Speedy execution matters to what Prelimmers do. We are autonomous,
              capable of making decisions in the moment, and going back to fix
              mistakes as they occur. Although we try to always be correct, we
              err on the side of taking action first. `}
            </div>
          </div>
          <div className="careers-values-item">
            <div className="careers-values-item-title">
              <div className="careers-values-item-number">3</div>Over
              Communicative Writers
            </div>
            <div className="careers-values-item-description">
              Prelimmers love writing and conversing with users and communicate
              constantly in short bursts and long summaries. We write specs,
              notes, plans, documentation and we appreciate well thought out
              explanations. We love sharing our emotions via emojis :)
            </div>
          </div>
          <div className="careers-values-item">
            <div className="careers-values-item-title">
              <div className="careers-values-item-number">4</div>Empathetic
            </div>
            <div className="careers-values-item-description">
              Prelimmers leave their ego at the door. The greater good of our
              mission, customers, and teammates are our North Star, and as such,
              we treat each other with respect and an empathetic ear. We hold
              each other accountable, but always with concern for each other.
            </div>
          </div>
          <div className="careers-values-item">
            <div className="careers-values-item-title">
              <div className="careers-values-item-number">5</div>Frugal &
              Scrappy
            </div>
            <div className="careers-values-item-description">
              {`Prelimmers accomplish more with less. We treat the companies money as if it
            was ours personally. Our constraints help facilitate our
            resourcefulness, self-sufficiency, and invention. We don't get extra
            points for growing headcount, budget size, or fixed expenses, instead giving and receiving praise for doing more with less.`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Benefits() {
  return (
    <div className="careers-benefits">
      <div className="careers-benefits-content">
        <div className="careers-benefits-title">Benefits</div>
        <div className="careers-benefits-subtitle">A few of our benefits</div>
        <div className="careers-benefits-row-1">
          <div className="careers-benefits-item">
            <div className="careers-benefits-item-title-container">
              <SvgHealthAndSafety className="careers-benefits-item-icon" />
              <div className="careers-benefits-item-title">
                Health Insurance
              </div>
            </div>
            <div className="careers-benefits-item-description">
              Prelim offers full health, vision, and dental insurance.
            </div>
          </div>
          <div className="careers-benefits-item">
            <div className="careers-benefits-item-title-container">
              <SvgEscalatorWarning className="careers-benefits-item-icon" />
              <div className="careers-benefits-item-title">Parental Leave</div>
            </div>
            <div className="careers-benefits-item-description">
              Prelim offers paid parental leave to employees of any gender who
              give birth, adopt, or foster a child.
            </div>
          </div>
          <div className="careers-benefits-item">
            <div className="careers-benefits-item-title-container">
              <SvgFlight className="careers-benefits-item-icon" />
              <div className="careers-benefits-item-title">Unlimited PTO</div>
            </div>
            <div className="careers-benefits-item-description">
              In addition to nationally recognized holidays, Prelim offers
              unlimited paid time off.
            </div>
          </div>
        </div>
        <div className="careers-benefits-row-2">
          <div className="careers-benefits-item">
            <div className="careers-benefits-item-title-container">
              <SvgPark />
              <div className="careers-benefits-item-title">Team Offsites</div>
            </div>
            <div className="careers-benefits-item-description">
              Twice a year, we get together to collaborate freely, so that we
              build cooler stuff and have fun doing it. Hackathons and chess
              tournaments typically make an appearance.
            </div>
          </div>
          <div className="careers-benefits-item">
            <div className="careers-benefits-item-title-container">
              <SvgLocalMall />
              <div className="careers-benefits-item-title">
                Remote Work Stipend
              </div>
            </div>
            <div className="careers-benefits-item-description">
              If your home is going to be your office too, we want it to be done
              right. New Prelimmers receive a stipend to get properly set up
              with the right equipment.
            </div>
          </div>
          <div className="careers-benefits-item">
            <div className="careers-benefits-item-title-container">
              <SvgRequestQuote />
              <div className="careers-benefits-item-title">
                Equity & Bonuses
              </div>
            </div>
            <div className="careers-benefits-item-description">
              {`We believe in rewarding the team for hard work, and Prelimmers get
              compensated in both equity and end of year bonuses based upon the
              team's goals.`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Jobs() {
  const [jobs, setJobs] = React.useState([]);
  React.useEffect(() => {
    LeverActions.getPostings().then((res) => {
      setJobs(res);
    });
  }, []);

  var jobsByTeam = {};
  for (var job of jobs) {
    var team = job.categories.team;
    if (jobsByTeam[team]) {
      jobsByTeam[team].push(job);
    } else {
      jobsByTeam[team] = [job];
    }
  }
  return (
    <div className="careers-jobs">
      <div className="careers-jobs-content">
        <div className="careers-jobs-title">Careers</div>
        <div className="careers-jobs-subtitle">Join our growing team!</div>
        <div className="careers-jobs-container">
          {Object.entries(jobsByTeam).map(([team, jobs], index) => {
            var titleBg = "#7A86E4";
            if (index % 4 === 1) {
              titleBg = "#FF9789";
            } else if (index % 4 === 2) {
              titleBg = "#F091B2";
            } else if (index % 4 === 3) {
              titleBg = "#EBF2B9";
            }
            return (
              <div key={team} className="careers-jobs-team">
                <div
                  className="careers-jobs-team-title"
                  style={{ background: titleBg }}
                >
                  {team}
                </div>
                <div className="careers-jobs-list">
                  {jobs.map((job) => {
                    return (
                      <div key={job.id} className="careers-jobs-job">
                        <div className="careers-jobs-job-copy">
                          <div className="careers-jobs-job-title">
                            {job.text}
                          </div>
                          <div className="careers-jobs-job-location">
                            {job.country} {job.workplaceType}
                          </div>
                        </div>
                        <div className="careers-jobs-job-link-container">
                          <a
                            className="careers-jobs-job-link"
                            href={job.hostedUrl}
                          >
                            Apply
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function Careers() {
  var title = "DAO Platform - Careers | Prelim";
  var description =
    "Prelim is the #1 platform for financial institutions to work with customers digitally. Join our growing team!";

  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero />
      <Mission />
      <Values />
      <Benefits />
      <Jobs />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default Careers;
