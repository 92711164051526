import React from "react";

const Svg = (props) => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.318359 4.73302C0.318359 1.80774 3.3557 -0.128099 6.00739 1.10713L21.1622 8.1666C24.2435 9.60194 24.2435 13.9831 21.1622 15.4184L6.00739 22.4779C3.3557 23.7131 0.318359 21.7773 0.318359 18.852V4.73302Z"
      fill="white"
    />
  </svg>
);

export default Svg;
