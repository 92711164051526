import React from "react";

const Svg = (props) => (
  <svg
    width="1262"
    height="874"
    viewBox="0 0 1262 874"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_2094_5198)">
      <path
        d="M104.838 525.099C7.09298 754.192 801.625 584.773 1065.12 86.2739L691.625 3.77391C532.24 70.1485 202.584 296.005 104.838 525.099Z"
        fill="url(#paint0_linear_2094_5198)"
      />
    </g>
    <g filter="url(#filter1_f_2094_5198)">
      <rect
        x="518"
        y="436.721"
        width="94.5684"
        height="523.793"
        rx="47.2842"
        transform="rotate(-61.0108 518 436.721)"
        fill="#FF9789"
      />
    </g>
    <g filter="url(#filter2_f_2094_5198)">
      <path
        d="M1006.76 785.092C671.551 773.608 443.372 404.739 371.927 200.031L1203 348.431C1240.08 438.544 1214.5 792.208 1006.76 785.092Z"
        fill="url(#paint1_linear_2094_5198)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2094_5198"
        x="93.5566"
        y="0.773926"
        width="974.568"
        height="627.813"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1.5"
          result="effect1_foregroundBlur_2094_5198"
        />
      </filter>
      <filter
        id="filter1_f_2094_5198"
        x="334.984"
        y="170.984"
        width="870.031"
        height="702.607"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_2094_5198"
        />
      </filter>
      <filter
        id="filter2_f_2094_5198"
        x="367.926"
        y="196.031"
        width="853.293"
        height="593.166"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2094_5198"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2094_5198"
        x1="-165.073"
        y1="759.639"
        x2="-258.308"
        y2="242.772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2037DC" />
        <stop offset="0.708333" stopColor="#2037DC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2094_5198"
        x1="726.657"
        y1="848.566"
        x2="933.652"
        y2="451.046"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#E5FF45" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
