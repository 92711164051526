import React from "react";
import HelmetHeader from "./helmetHeader";
import Header from "./header";
import Footer from "./footer";
import CallToAction from "./call_to_action";
import "../css/not_found.css";

function Hero() {
  return (
    <div className="not-found-hero">
      <div className="not-found-content">
        <div className="not-found-title">404</div>
        <div className="not-found-subtitle">Not Found!</div>
        <div className="not-found-description">{`Uh oh! Looks like we couldn't find the page you're looking for.`}</div>
      </div>
    </div>
  );
}

function NotFound() {
  return (
    <div>
      <HelmetHeader />
      <Header />
      <Hero />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default NotFound;
