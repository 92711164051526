import React from "react";

const Svg = (props) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5921 16.5146V16.5139H16.5963V10.4531C16.5963 7.48809 15.9551 5.2041 12.473 5.2041C10.7991 5.2041 9.67573 6.11852 9.21713 6.98543H9.16871V5.48091H5.86719V16.5139H9.30498V11.0507C9.30498 9.61233 9.57889 8.22142 11.3683 8.22142C13.1315 8.22142 13.1578 9.86297 13.1578 11.143V16.5146H16.5921Z"
      fill="currentColor"
    />
    <path
      d="M0.267578 5.49414H3.70952V16.5271H0.267578V5.49414Z"
      fill="currentColor"
    />
    <path
      d="M1.9935 0C0.892996 0 0 0.888944 0 1.98446C0 3.07998 0.892996 3.98751 1.9935 3.98751C3.09401 3.98751 3.98701 3.07998 3.98701 1.98446C3.98632 0.888944 3.09332 0 1.9935 0V0Z"
      fill="currentColor"
    />
  </svg>
);

export default Svg;
