import React from "react";

const Svg = (props) => (
  <svg
    width="1014"
    height="987"
    viewBox="0 0 1014 987"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_2040_4987)">
      <path
        d="M521.861 944.168C88.1849 975.497 -6.66791 706.443 0.115234 568L830.885 709.103C908.576 774.404 955.538 912.839 521.861 944.168Z"
        fill="url(#paint0_linear_2040_4987)"
      />
    </g>
    <g filter="url(#filter1_f_2040_4987)">
      <rect
        x="-183"
        y="350.819"
        width="112.382"
        height="607.226"
        rx="50"
        transform="rotate(-41.4751 -183 350.819)"
        fill="#0E24CE"
      />
    </g>
    <g filter="url(#filter2_f_2040_4987)">
      <rect
        x="-39.5078"
        y="240.89"
        width="108.063"
        height="419.944"
        rx="50"
        transform="rotate(-33.1628 -39.5078 240.89)"
        fill="#FF9789"
      />
    </g>
    <g filter="url(#filter3_f_2040_4987)">
      <path
        d="M740.5 946.5C414.1 1023.7 86.8333 685.667 -36 507L970 618.5C1029.5 695.667 1066.9 869.3 740.5 946.5Z"
        fill="url(#paint1_linear_2040_4987)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2040_4987"
        x="-3.2168"
        y="565"
        width="888.1"
        height="384.683"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1.5"
          result="effect1_foregroundBlur_2040_4987"
        />
      </filter>
      <filter
        id="filter1_f_2040_4987"
        x="-362.424"
        y="96.9658"
        width="845.211"
        height="888.237"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_2040_4987"
        />
      </filter>
      <filter
        id="filter2_f_2040_4987"
        x="-220.307"
        y="0.978516"
        width="681.777"
        height="772.253"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="100"
          result="effect1_foregroundBlur_2040_4987"
        />
      </filter>
      <filter
        id="filter3_f_2040_4987"
        x="-40"
        y="503"
        width="1053.73"
        height="458.778"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2040_4987"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2040_4987"
        x1="341.363"
        y1="1049.1"
        x2="509.651"
        y2="612.786"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2037DC" />
        <stop offset="0.708333" stopColor="#2037DC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2040_4987"
        x1="487"
        y1="1081.5"
        x2="582"
        y2="643.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#E5FF45" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
