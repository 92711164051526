import React from "react";
import "../css/terms_of_service.css";
import Header from "./header";
import Footer from "./footer";
import HelmetHeader from "./helmetHeader";

function TermsAndConditions() {
  var title = "Prelim Legal Terms and Conditions";
  var description =
    "Prelim is the #1 platform for financial institutions to work with customers digitally. Go digital with zero code using one white-labeled, all-in one platform that integrates with your current technology stack.";
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <div className="c14 doc-content">
        <div>
          <p className="c15 c18">
            <span className="c4 c22"></span>
          </p>
        </div>
        <p className="c7 c13">
          <span className="c5 c1 c19">PRELIM TECHNOLOGIES, INC.</span>
        </p>
        <p className="c13 c7">
          <span className="c19 c5 c1">TERMS AND CONDITIONS </span>
        </p>
        <p className="c20">
          <span className="c1">Last updated: 2024-05-13</span>
        </p>
        <ol className="c3 lst-kix_list_2-0 start" start="1">
          <li className="c12 c10">
            <span className="c2 c1">Definitions</span>
            <span className="c4 c1">
              . For purposes of this Agreement, the following terms have the
              following meanings:
            </span>
          </li>
        </ol>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Access Credentials</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means the username, identification number, password,
            license or security key, security token, PIN or other security code,
            method, technology or device used, alone or in combination, to
            verify an individual&#39;s identity and authorization to access and
            use the Services.{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Agreement</span>
          <span className="c5 c1">
            &rdquo; means the SaaS Services Agreement (&ldquo;
          </span>
          <span className="c8 c5 c1">Agreement</span>
          <span className="c5 c1">
            &rdquo;) and incorporates any accompanying order form (&ldquo;
          </span>
          <span className="c8 c5 c1">Order Form</span>
          <span className="c5 c1">
            &rdquo;), service level agreement (&ldquo;
          </span>
          <span className="c8 c5 c1">Service Level Agreement</span>
          <span className="c4 c1">
            &rdquo;), or any other exhibits or schedules thereof. Any
            capitalized term not defined herein shall have the meaning set forth
            in the Agreement.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Affiliate</span>
          <span className="c5 c1">&rdquo;</span>
          <span className="c8 c5 c1">&nbsp;</span>
          <span className="c4 c1">
            means any entity controlling, controlled by or under common control
            with a Party, where &ldquo;control&rdquo; is defined as (i) the
            ownership of at least 50% of the equity or beneficial interests of
            such entity; or (ii) the right to vote for or appoint a majority of
            the board of directors or other governing body of such entity; or
            (iii) the power to exercise a controlling influence over the
            management or policies of the entity.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Customer</span>
          <span className="c4 c1">
            &rdquo; means, the entity listed on the Order Form and any users
            permitted to use the Services pursuant to this Agreement.{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Customer Data</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means, other than Resultant Data, nonpublic
            information, data and other content, in any form or medium, that is
            collected, downloaded, or otherwise received, directly or indirectly
            from Customer by or through the Services.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Customer Systems</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means the Customer&#39;s information technology
            infrastructure, including computers, software, hardware, databases,
            electronic systems (including database management systems) and
            networks, whether operated directly by Customer or through the use
            of third-party services.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Confidential Information</span>
          <span className="c4 c1">
            &rdquo; means any information that is treated as confidential by
            either Party, including trade secrets, technology, information
            pertaining to business operations and strategies, and information
            pertaining to customers, pricing, and marketing. Confidential
            Information includes Prelim Materials. Confidential Information does
            not include information after 5 years following disclosure or
            information that the Receiving Party can demonstrate by
            documentation: (a) was already known to the Receiving Party without
            restriction on use or disclosure prior to receipt of such
            information directly or indirectly from or on behalf of the
            Disclosing Party; (b) was or is independently developed by the
            Receiving Party without reference to or use of any of the Disclosing
            Party&#39;s Confidential Information; (c) was or becomes generally
            known by the public other than by breach of this Agreement by, or
            other wrongful act of, the Receiving Party or any of its
            Representatives; or (d) was received by the Receiving Party from a
            third party who was not, at the time, under any obligation to the
            Disclosing Party or any other Person to maintain the confidentiality
            of such information.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Harmful Code</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means any: (a) virus, trojan horse, worm, backdoor, or
            other software or hardware devices the effect of which is to permit
            unauthorized access to, or to disable, erase, or otherwise harm, any
            computer, systems, or software; or (b) time bomb, drop dead device,
            or other software or hardware device designed to disable a computer
            program automatically with the passage of time or under the positive
            control of any Person, or otherwise deprive Customer of its lawful
            right to use such Software.{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Intellectual Property Rights</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means all or any of the following: (a) patents, patent
            disclosures, and inventions (whether patentable or not); (b)
            trademarks, service marks, trade dress, trade names, logos,
            corporate names, and domain names, together with all of the goodwill
            associated therewith; (c) copyrights and copyrightable works
            (including computer programs), mask works, and rights in data and
            databases; (d) trade secrets, know-how, and other Confidential
            Information; and (e) all other intellectual property rights, in each
            case whether registered or unregistered and including all
            applications for, and renewals or extensions of, such rights, and
            all similar or equivalent rights or forms of protection provided by
            applicable Law in any jurisdiction throughout the world.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Law</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means any statute, law, ordinance, regulation, rule,
            code, order, constitution, treaty, common law, judgment, decree,
            other requirement, or rule of law of any federal, state, local or
            foreign government, or political subdivision thereof, or any
            arbitrator, court, or tribunal of competent jurisdiction.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Losses</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means any and all losses, damages, liabilities,
            deficiencies, claims, actions, judgments, settlements, interest,
            awards, penalties, fines, costs or expenses of whatever kind,
            including reasonable attorneys&#39; fees and the costs of enforcing
            any right to indemnification hereunder and the cost of pursuing any
            insurance providers.{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Prelim Materials</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means the Service Software, Prelim Systems, and any and
            all other information, data, documents, materials, works and other
            content, devices, methods, processes, hardware, software and other
            technologies and inventions, including any deliverables, technical
            or functional descriptions, requirements, plans or reports, that are
            provided or used by Prelim or any Subcontractor (defined in Section
            2.4) in connection with the Services or otherwise comprise or relate
            to the Services or Prelim Systems. For the avoidance of doubt,
            Prelim Materials include Resultant Data and any information, data or
            other content derived from Prelim&#39;s monitoring of Customer&#39;s
            access to or use of the Services, but do not include Customer Data.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c5 c1 c8">Prelim Personnel</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means all individuals involved in the performance of
            Services as employees, agents, or independent contractors of Prelim
            or any Subcontractor.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Prelim Systems</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means the information technology infrastructure used by
            or on behalf of Prelim in performing the Services, including all
            computers, software, hardware, databases, electronic systems
            (including database management systems) and networks, whether
            operated directly by Prelim or through the use of third-party
            services.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Permitted Use</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means any use of the Services by Customer in
            conformance with this Agreement.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Person</span>
          <span className="c4 c1">
            &rdquo;&nbsp;an individual, corporation, partnership, limited
            liability company, association, joint stock company, joint venture,
            estate, trust, unincorporated organization, or other entity or
            organization, or a local, state, United States, or foreign
            governmental or public body or authority, or any agency or
            subdivision thereof.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Personal Information</span>
          <span className="c4 c1">
            &rdquo; means name, address, telephone number, and any information
            that, individually or in combination, does or can identify a
            specific individual or by or from which a specific individual may be
            identified, contacted, or located.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Process</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means to take any action or perform any operation or
            set of operations that the Services are capable of taking or
            performing on any data, information or other content.
            &ldquo;Processing&rdquo; and &ldquo;Processed&rdquo; have
            correlative meanings.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Representatives</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means, with respect to a Party, that Party&#39;s
            employees, officers, independent contractors, service providers,
            subcontractors, accountants and legal advisors.{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Resultant Data</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means information, data, and other content that is
            derived by or through the Services from Processing Customer Data and
            is sufficiently different from such Customer Data that such Customer
            Data cannot be reverse engineered or otherwise identified from the
            inspection, analysis, or further Processing of such information,
            data, or content.
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Service Software</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means the Prelim software application or applications
            and any third-party or other software, and all new versions,
            updates, revisions, improvements, and modifications of the
            foregoing, that Prelim provides remote access to and use of as part
            of the Services.{" "}
          </span>
        </p>
        <p className="c0">
          <span className="c5 c1">&ldquo;</span>
          <span className="c8 c5 c1">Third Party Materials</span>
          <span className="c4 c1">
            &rdquo;&nbsp;means materials and information, in any form or medium,
            including any open-source or other software, documents, data,
            content, specifications, products, equipment, or components of or
            relating to the Services that are not proprietary to Prelim.{" "}
          </span>
          <a id="id.30j0zll"></a>
        </p>
        <ol className="c3 lst-kix_list_2-0" start="2">
          <li className="c0 c10">
            <span className="c2 c1">Services</span>
            <a id="id.1fob9te"></a>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c0 c9">
            <span className="c2 c1">Services</span>
            <span className="c4 c1">
              . Subject to and conditioned on Customer&#39;s compliance with the
              terms and conditions of this Agreement, during the Term (defined
              in Section 9.2) Prelim will use commercially reasonable efforts to
              provide to Customer the Service (as defined in the Order Form) in
              accordance with the terms and conditions hereof and, if
              applicable, in accordance with any Service Level Agreement which
              may be attached as an exhibit to the Agreement, except for:
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c0 c11">
            <span className="c4 c1">
              Scheduled Downtime set forth in the Service Level Agreement, if
              applicable;
            </span>
          </li>
          <li className="c0 c11">
            <span className="c4 c1">
              Service downtime or degradation due to a Force Majeure Event;
            </span>
          </li>
          <li className="c0 c11">
            <span className="c4 c1">
              any other circumstances beyond Prelim&#39;s reasonable control,
              including Customer&#39;s use of Third Party Materials, misuse of
              the Services, or use of the Services other than in compliance with
              the express terms of this Agreement; and
            </span>
          </li>
          <li className="c0 c11">
            <span className="c4 c1">
              any suspension or termination of Customer&#39;s access to or use
              of the Services as permitted by this Agreement.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1" start="2">
          <li className="c0 c9">
            <span className="c2 c1">Service and System Control</span>
            <span className="c4 c1">
              . Except as otherwise expressly provided in this Agreement, as
              between the Parties:
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c0 c11">
            <span className="c4 c1">
              Prelim has and will retain sole control over the operation,
              provision, maintenance, and management of the Services and Prelim
              Materials, including the: (i) Prelim Systems; (ii) locations where
              any of the Services are performed; (iii) selection, deployment,
              modification, and replacement of the Service Software; and (iv)
              performance of Services maintenance, upgrades, corrections, and
              repairs; and
            </span>
          </li>
          <li className="c0 c11">
            <span className="c4 c1">
              Customer has and will retain sole control over the operation,
              maintenance, and management of, and all access to and use of, the
              Customer Systems, and sole responsibility for all access to and
              use of the Services and Prelim Materials by any Person by or
              through the Customer Systems or any other means controlled by
              Customer, including any: (i) information, instructions, or
              materials provided by any of them to the Services or Prelim; (ii)
              results obtained from any use of the Services or Prelim Materials;
              and (iii) conclusions, decisions, or actions based on such use.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1" start="3">
          <li className="c0 c9">
            <span className="c2 c1">Changes</span>
            <span className="c5 c1">
              . Prelim reserves the right, in its sole discretion, to make any
              changes to the Services and Prelim Materials that it deems
              necessary or useful to: (a) maintain or enhance (i) the quality or
              delivery of Prelim&#39;s services to its clients, (ii) the
              competitive strength of or market for Prelim&#39;s services, or
              (iii) the Services&#39; cost efficiency or performance; or (b
            </span>
            <a id="id.3znysh7"></a>
            <span className="c4 c1">) to comply with applicable Law. </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Subcontractors</span>
            <span className="c5 c1">
              . Prelim may from time to time in its discretion engage third
              parties to perform Services (each a &ldquo;
            </span>
            <span className="c8 c5 c1">Subcontractor</span>
            <span className="c4 c1">&rdquo;). </span>
            <a id="id.2et92p0"></a>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Suspension or Termination of Services</span>
            <span className="c5 c1">
              . Prelim may suspend, terminate, or otherwise deny Customer&#39;s
              or any other Person&#39;s access to or use of all or any part of
              the Services or Prelim Materials, without incurring any resulting
              obligation or liability, if: (a) Prelim receives a judicial or
              other governmental demand, order, or request that requires Prelim
              to do so; or (b) Prelim believes, in its sole discretion, that:
              (i) Customer has failed to comply with, any term of this
              Agreement, or accessed or used the Services beyond the scope of
              the rights granted or for a purpose not authorized under this
              Agreement; (ii) Customer is, has been, or is likely to be involved
              in any fraudulent, misleading, or unlawful activities relating to
              or in connection with any of the Services; or (iii) this Agreement
              expires or is terminated. This Section 2.5 does not limit any of
              Prelim
            </span>
            <a id="id.tyjcwt"></a>
            <span className="c4 c1">
              &#39;s other rights or remedies, whether at law, in equity, or
              under this Agreement.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="3">
          <li className="c0 c10">
            <span className="c2 c1">
              Authorization and Customer Restrictions
            </span>
            <a id="id.3dy6vkm"></a>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c0 c9">
            <span className="c2 c1">Authorization</span>
            <span className="c4 c1">
              . Subject to and conditioned on Customer&#39;s payment of the Fees
              (defined in Section 6.1) and compliance and performance in
              accordance with all other terms and conditions of this Agreement,
              Prelim hereby authorizes Customer during the Term to access and
              use the Services and such Prelim Materials as Prelim may supply or
              make available to Customer solely for the Permitted Use and the
              conditions and limitations set forth in this Agreement. This
              authorization is non-exclusive and, other than as may be expressly
              set forth in Section 13.6, non-transferable.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Reservation of Rights</span>
            <span className="c5 c1">
              . Nothing in this Agreement grants any right, title, or interest
              in or to (including any license under) any Intellectual Property
              Rights in or relating to, the Services, Prelim Materials, or Third
              Party Materials. All right, title, and interest in and to the
              Services, the Prelim Materials, and the Third Party Materials are
              and will remain with Prelim{" "}
            </span>
            <a id="id.1t3h5sf"></a>
            <span className="c4 c1">
              and the respective rights holders in the Third Party Materials.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">
              Authorization Limitations and Restrictions
            </span>
            <span className="c4 c1">
              . Customer will not, and will not permit any other Person to,
              access or use the Services or Prelim Materials except as expressly
              permitted by this Agreement and, in the case of Third-Party
              Materials, the applicable third-party license agreement. Without
              limiting the generality of the foregoing, Customer will not,
              except as this Agreement expressly permits:
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c6 c7">
            <span className="c4 c1">
              copy, modify, or create derivative works or improvements of the
              Services or Prelim Materials;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              reverse engineer, disassemble, decompile, decode, adapt, or
              otherwise attempt to derive or gain access to the source code of
              the Services or Prelim Materials, in whole or in part;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              bypass or breach any security device or protection used by the
              Services or Prelim Materials or access or use any of the Services
              or Prelim Materials except through the use of then valid Access
              Credentials;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              input, upload, transmit, or otherwise provide to or through the
              Services or Prelim Systems, any information or materials that are
              unlawful or injurious, or contain, transmit, or activate any
              Harmful Code;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              damage, destroy, disrupt, disable, impair, interfere with, or
              otherwise impede or harm in any manner the Services, Prelim
              Systems, or Prelim&#39;s provision of services to any third party,
              in whole or in part;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              access or use the Services or Prelim Materials in any manner or
              for any purpose that infringes, misappropriates, or otherwise
              violates any Intellectual Property Right or other right Prelim or
              any third party or that violates any applicable Law;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              access or use the Services or Prelim Materials for purposes of
              competitive analysis of the Services or Prelim Materials, the
              development, provision, or use of a competing software service or
              product, or any other purpose that is to Prelim&#39;s detriment or
              commercial disadvantage;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              access or use the Services or Prelim Materials in, or in
              association with, the design, construction, maintenance, operation
              of any hazardous environments, systems, or applications, any
              safety response systems or other safety-critical applications, or
              any other use or application in which the use or failure of the
              Services could lead to personal injury or property damage; or
            </span>
          </li>
          <li className="c6 c7">
            <span className="c5 c1">
              otherwise access or use the Services or Prelim Materials beyond
              the scope of the authorization granted under Section{" "}
            </span>
            <span className="c5 c1">3.1</span>
            <span className="c4 c1">.</span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              remove or export from the United States or allow the export or
              re-export of the Services, Prelim Materials, or anything related
              thereto, or any direct product thereof in violation of any
              restrictions, laws or regulations of the United States Department
              of Commerce, the United States Department of Treasury Office of
              Foreign Assets Control, or any other United States or foreign
              agency or authority. As defined in FAR section 2.101, the Prelim
              Software and documentation are &ldquo;commercial items&rdquo; and
              according to DFAR section 252.227 7014(a)(1) and (5) are deemed to
              be &ldquo;commercial computer software&rdquo; and
              &ldquo;commercial computer software documentation.&rdquo;
              &nbsp;Consistent with DFAR section 227.7202 and FAR section
              12.212, any use modification, reproduction, release, performance,
              display, or disclosure of such commercial software or commercial
              software documentation by the U.S. Government will be governed
              solely by the terms of this Agreement and will be prohibited
              except to the extent expressly permitted by the terms of this
              Agreement.{" "}
            </span>
            <a id="id.4d34og8"></a>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="4">
          <li className="c0 c10">
            <span className="c2 c1">Customer Obligations</span>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c0 c9">
            <span className="c2 c1">Customer Systems and Cooperation</span>
            <span className="c5 c1">
              . Customer will at all times during the Term: (a) set up,
              maintain, and operate in good repair all Customer Systems on or
              through which the Services are accessed or used; and (b) provide
              all cooperation and assistance as Prelim may reasonably request to
              enable Prelim to exercise its rights and p
            </span>
            <a id="id.2s8eyo1"></a>
            <span className="c4 c1">
              erform its obligations under and in connection with this
              Agreement.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Effect of Customer Failure or Delay</span>
            <span className="c5 c1">
              . Prelim is not responsible or liable for any delay or failure of
              performance caused in whole or in part by Customer&#39;s delay in
              performing, or failure to perform, any of its obligations under
              this Agreement (each, a &ldquo;
            </span>
            <span className="c8 c5 c1">Customer Failure</span>
            <a id="id.17dp8vu"></a>
            <span className="c4 c1">&rdquo;). </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Corrective Action and Notice</span>
            <span className="c5 c1">
              . If Customer becomes aware of any actual or threatened activity
              prohibited by Section{" "}
            </span>
            <span className="c5 c1">3.3</span>
            <span className="c5 c1">
              , Customer will immediately: (a) take all reasonable and lawful
              measures within its control that are necessary to stop the
              activity or threatened activity and to mitigate its effects; and
              (b) notify Prelim of any{" "}
            </span>
            <a id="id.3rdcrjn"></a>
            <span className="c4 c1">such actual or threatened activity.</span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Prelim Policies</span>
            <span className="c4 c1">
              . Customer represents, covenants, and warrants that Customer will
              use the Services only in compliance with Prelim&rsquo;s standard
              published policies then in effect and all applicable Laws.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Compliance</span>
            <span className="c4 c1">
              . Although Prelim has no obligation to monitor Customer&rsquo;s
              use of the Services, Prelim may do so and may prohibit any use of
              the Services it believes may be (or alleged to be) in violation of
              the foregoing.
            </span>
          </li>
        </ol>
        <a id="id.26in1rg"></a>
        <ol className="c3 lst-kix_list_2-0" start="5">
          <li className="c0 c10">
            <span className="c2 c1">Security</span>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c0 c9">
            <span className="c2 c1">Customer Control and Responsibility</span>
            <span className="c5 c1">
              . Customer has and will retain sole responsibility for: (a) all
              Customer Data, including its content and use; (b) all information,
              instructions, and materials provided by or on behalf of Customer
              in connection with the Services; (c) Customer&#39;s information
              technology infrastructure, including computers, software,
              databases, electronic systems (including database management
              systems), and networks, whether operated directly by Customer or
              through the use of third-party services (&ldquo;
            </span>
            <span className="c8 c5 c1">Customer Systems</span>
            <span className="c4 c1">
              &rdquo;); (d) the security and use of Customer&#39;s Access
              Credentials; and (e) all access to and use of the Services and
              Prelim Materials directly or indirectly by or through the Customer
              Systems or its Access Credentials, with or without
              Customer&rsquo;s knowledge or consent.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Access and Security</span>
            <span className="c4 c1">
              . Customer will be responsible for all processes necessary to
              securely administer the distribution and use of all Access
              Credentials, protect against any unauthorized access to or use of
              the Services, and control the content and use of Customer Data,
              including the uploading or other provision of Customer Data for
              Processing by the Services.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Personal Information</span>
            <span className="c4 c1">. &nbsp;</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c6">
            <span className="c4 c1">
              Prelim will use commercially reasonable efforts not cause or
              permit any Personal Information to be processed in any manner or
              for any purpose other than the performance of the Services in
              compliance with the obligations and restrictions set forth in this
              Agreement and all applicable Laws.
            </span>
          </li>
          <li className="c6">
            <span className="c5 c1">
              Prelim will promptly notify Customer in writing if Prelim becomes
              aware of any unauthorized access, use, or other act respecting
              Personal Information or if Prelim becomes the subject of any
              government, regulatory, or other investigation or proceeding
              relating to its privacy, data sec
            </span>
            <a id="id.lnxbz9"></a>
            <span className="c4 c1">urity, or handling practices.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="6">
          <li className="c0 c10">
            <span className="c2 c1">Fees; Payment Terms</span>
            <a id="id.35nkun2"></a>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c0 c9">
            <span className="c2 c1">Fees</span>
            <span className="c5 c1">
              . Customer will pay Prelim the then applicable fees described in
              the Order Form for the Services and Implementation Services in
              accordance with the terms therein, in addition to the Third Party
              Vendor Fees (as defined below) (the &ldquo;
            </span>
            <span className="c8 c5 c1">Fees</span>
            <span className="c4 c1">
              &rdquo;). &nbsp;If Customer&rsquo;s use of the Services exceeds
              the Service Capacity set forth on the Order Form or otherwise
              requires the payment of additional fees (per the terms of this
              Agreement), Customer shall be billed for such usage as may be set
              forth in the Order Form and Customer agrees to pay the additional
              fees in the manner provided herein.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Fee Increases</span>
            <span className="c5 c1">. Prelim may increase Fees </span>
            <span className="c1">
              after the appliable Initial Term or Renewal Term by
            </span>
            <span className="c5 c1">
              &nbsp;providing written notice to Customer at least 30 calendar
              days prior to the commencement of such Term the Order Form will be
              deemed amended accordingly;{" "}
            </span>
            <span className="c5 c21 c1">provided</span>
            <span className="c5 c1">, </span>
            <span className="c5 c1 c21">however</span>
            <span className="c5 c1">
              , that upon renewal of any Term, per Section 9.2,{" "}
            </span>
            <span className="c1">
              the fee shall automatically, without any further action by either
              party, increase by 5% at the start of each Renewal Term.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Taxes</span>
            <span className="c4 c1">
              . All Fees and other amounts payable by Customer under this
              Agreement are exclusive of taxes and similar assessments. Customer
              is responsible for all sales, use and excise taxes, and any other
              similar taxes, duties, and charges of any kind imposed by any
              federal, state, or local governmental or regulatory authority on
              any amounts payable by Customer hereunder, other than any taxes
              imposed on Prelim&#39;s income.{" "}
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Payment</span>
            <span className="c4 c1">
              . Unless set forth in the Order Form, invoices are due in full 30
              days after the invoice date. Past due amounts will incur interest
              until paid at the lesser of: (a) 1.5% per month; and (b) the
              highest rate permitted by Law, plus all reasonable expenses of
              collection. All amounts will be invoiced and paid in U.S. Dollars.
              Customer agrees to reimburse Prelim for all expenses, including
              reasonable attorneys&#39; fees, incurred by Prelim in the
              collection of any overdue and unpaid invoices.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">No Deductions or Setoffs</span>
            <span className="c1 c4">
              . All amounts payable to Prelim under this Agreement will be paid
              by Customer to Prelim in full without any setoff, recoupment,
              counterclaim, deduction, debit, or withholding for any reason
              (other than Service Credits issued pursuant to any applicable
              Service Level Agreement, if any, or any deduction or withholding
              of tax as may be required by applicable Law).
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Billing Disputes</span>
            <span className="c5 c1">
              . If Customer believes that Prelim has billed Customer
              incorrectly, Customer must contact Prelim no later than 60 days
              after the closing date on the first billing statement in which the
              error or problem appeared, in order to receive an adjustment or
              credit. Failure by Customer to contact Prelim within 60 days will
              be deemed acceptance by Customer of the accuracy of said billing
              statement. Inquiries should be directed{" "}
            </span>
            <span className="c1">to the Company&#39;s</span>
            <span className="c4 c1">
              &nbsp;customer support department. Each Party agrees to work in
              good faith to resolve any such dispute.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Third Party Vendor Fees</span>
            <span className="c5 c1">
              . In addition to the Fees set forth in the Order Form, the Company
              integrates with other products and services, and the providers of
              such products and services may charge separate fees which the
              Company shall include in the invoices from the Company to the
              Customer (the &ldquo;
            </span>
            <span className="c8 c5 c1">Third Party Vendor Fees&rdquo;).</span>
            <span className="c5 c1">&nbsp;Customer</span>
            <span className="c1">&nbsp;</span>
            <span className="c4 c1">
              is responsible for all fees from such third party products and
              services.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="7">
          <li className="c0 c10">
            <span className="c2 c1">Intellectual Property Rights</span>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c0 c9">
            <span className="c2 c1">Services and Prelim Materials</span>
            <span className="c5 c1">
              . All right, title, and interest in and to the Services and Prelim
              Materials, including all Intellectual Property Rights therein, are
              and will remain with Prelim and the respective rights holders in
              the Third-Party Materials. Customer has no right, license, or
              authorization with respect to any of the Services or Prelim
              Materials (including Third-Party Materials) except as expressly
              set forth in Section{" "}
            </span>
            <span className="c5 c1">3.1</span>
            <span className="c4 c1">
              &nbsp;or the applicable third-party license, in each case subject
              to Section 3.3. All other rights in and to the Services and Prelim
              Materials (including Third-Party Materials) are expressly reserved
              by Prelim and the respective third-party licensors. In furtherance
              of the foregoing, Customer hereby unconditionally and irrevocably
              grants to Prelim an assignment of all right, title, and interest
              in and to the Resultant Data, including all Intellectual Property
              Rights relating thereto.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Customer Data</span>
            <span className="c1 c5">
              . As between Customer and Prelim, Customer is and will remain the
              sole and exclusive owner of all right, title, and interest in and
              to all Customer Data, including all Intellectual Property Rights
              relating thereto, subject to the rights and permissions granted in
              Section 7.3
            </span>
            <a id="id.1ksv4uv"></a>
            <span className="c4 c1">.</span>
          </li>
          <li className="c0 c9">
            <span className="c1 c2">Consent to Use Customer Data</span>
            <span className="c5 c1">
              . Customer hereby irrevocably grants all such rights and
              permissions in or relating to Customer Data: (a) to Prelim, its
              Subcontractors, and the Prelim Personnel as are necessary or
              useful to perform the Services; and (b) to Prelim as are necessary
              or useful to enforce this Agreement and exercise its rights and
              perform its obligations{" "}
            </span>
            <a id="id.44sinio"></a>
            <span className="c4 c1">
              hereunder. Notwithstanding anything to the contrary, Prelim shall
              have the right to collect and analyze data and other information
              relating to the provision, use and performance of various aspects
              of the Services and related systems and technologies (including,
              without limitation, information concerning Customer Data and data
              derived therefrom), and Prelim will be free (during and after the
              term hereof) to (i) use such information and data to improve and
              enhance the Services and for other development, diagnostic and
              corrective purposes in connection with the Services and other
              Company offerings, and (ii) disclose such data solely in aggregate
              or other de-identified form in connection with its business.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="8">
          <li className="c12 c10">
            <span className="c2 c1">Confidentiality</span>
            <a id="id.2jxsxqh"></a>
            <span className="c4 c1">. </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c12 c9">
            <span className="c2 c1">
              Protection of Confidential Information
            </span>
            <span className="c5 c1">
              . The Parties acknowledge that, in connection with this Agreement,
              each Party (the &ldquo;Receiving Party&rdquo;) may obtain
              Confidential Information of the other Party (the &ldquo;Disclosing
              Party&rdquo;). Such Confidential Information will not be used or
              disclosed by the Receiving Party except as specifically
              authorized, or as necessary to perform or improve the Services.
              The Receiving Party agrees to keep such Confidential Information
              confidential and to accord such Confidential Information the same
              standards and procedures it uses to protect its own confidential
              business information of similar nature (but not less than
              reasonable care), including limiting access to its Representatives
              on a need-to-know basis and only after such Persons have been
              informed of and are bound to maintain confidentiality. Nothing in
              this Agreement will prohibit or{" "}
            </span>
            <span className="c1">restrict the Receiving</span>
            <span className="c5 c1">
              &nbsp;Party&#39;s right to develop, use, or market products or
              services similar to or competitive with those of the Disclosing
              Party as disclosed in the Confidential Information as long as
              doing so will not thereby breach this Agreement.
            </span>
            <a id="id.z337ya"></a>
            <span className="c4 c1">&nbsp; </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Compelled Disclosures</span>
            <span className="c5 c1">
              . If the Receiving Party or any of its Representatives is
              compelled by applicable Law to disclose any Confidential
              Information then, to the extent permitted by applicable Law, the
              Receiving Party will: (a) promptly, and prior to such disclosure,
              notify the Disclosing Party in writing of such requirement so that
              the Disclosing Party can seek a protective order or other remedy;
              and (b) provide reasonable assistance to the Disclosing Party, at
              the Disclosing Party&#39;s sole cost and expense, in opposing such
              disclosure or seeking a protective order or other limitations on
              disclosure. If the Receiving Party remains required by Law to
              disclose any Confidential Information, the Receiving Party will
              disclose only that portion of the Confidential Information that
              the Receiving P
            </span>
            <a id="id.3j2qqm3"></a>
            <span className="c4 c1">arty is legally required to disclose.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="9">
          <li className="c0 c10">
            <span className="c2 c1">Term and Termination </span>
            <a id="id.1y810tw"></a>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c0 c9">
            <span className="c2 c1">Initial Term</span>
            <span className="c5 c1">
              . Unless specified in the Order Form, the initial term of this
              Agreement begins as of the Effective Date and, unless terminated
              earlier pursuant to any of the Agreement&#39;s express provisions,
              will continue in effect until five years from such date (the
              &ldquo;
            </span>
            <span className="c8 c5 c1">Initial Term</span>
            <span className="c4 c1">&rdquo;). &nbsp;</span>
            <a id="id.4i7ojhp"></a>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Renewal</span>
            <span className="c5 c1">
              . Unless specified in the Order Form, this Agreement will
              automatically renew for additional successive 12 month terms
              unless earlier terminated pursuant to this Agreement&#39;s express
              provisions or either Party gives the other Party written notice of
              non-renewal at least 30 days prior to the expiration of the
              then-current term (each a &ldquo;
            </span>
            <span className="c8 c5 c1">Renewal Term</span>
            <span className="c5 c1">
              &rdquo; and, together with the Initial Term, the &ldquo;
            </span>
            <span className="c8 c5 c1">Term</span>
            <span className="c4 c1">&rdquo;).</span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Termination</span>
            <span className="c5 c1">
              . In addition to any other express termination right set forth
              elsewhere in thi
            </span>
            <a id="id.2xcytpi"></a>
            <span className="c4 c1">s Agreement:</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c0 c11">
            <span className="c4 c1">
              Prelim may terminate this Agreement, effective on 60 day written
              notice to Customer, for any reason.
            </span>
          </li>
          <li className="c0 c11">
            <span className="c4 c1">
              Prelim may terminate this Agreement, effective on written notice
              to Customer, if Customer: (i) fails to pay any amount when due
              hereunder, and such failure continues more than 30 days after
              Prelim&#39;s delivery of written notice thereof; or (ii) breaches
              any of its obligations under Section 3.3 or Section 8.
            </span>
          </li>
          <li className="c12 c11">
            <span className="c5 c1">
              Either Party may terminate this Agreement upon written notice,
              effective immediately, if: (a) a petition under any bankruptcy Law
              is filed by or against the other Party; (b) a receiver is
              appointed for the other Party&#39;s assets; (c) the other Party
              becomes insolvent or takes advantage of any insolvency or any
              similar statute; &nbsp;or (d) the other Party is in material
              breach of this Agreement and such breach remains uncured, if
              curable, for 30 days
            </span>
            <a id="id.1ci93xb"></a>
            <span className="c4 c1">
              &nbsp;after written notice of the breach.{" "}
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1" start="4">
          <li className="c0 c9">
            <span className="c2 c1">Effect of Expiration or Termination</span>
            <span className="c4 c1">
              . Upon any expiration or termination of this Agreement, except as
              expressly otherwise provided in this Agreement:
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c0 c11">
            <span className="c4 c1">
              all rights, licenses, consents, and authorizations granted by
              either Party to the other under this Agreement will immediately
              terminate;
            </span>
          </li>
          <li className="c6">
            <span className="c5 c1">
              Customer will immediately cease all use of any Services or Prelim
              Materials and (i) promptly return to Prelim, or at Prelim&#39;s
              written request destroy, all documents and tangible materials
              containing, reflecting, incorporating, or based on any Prelim
              Materials or Prelim&#39;s Confidential Information; and (iii) if
              requested by Prelim, an authorized officer of Customer will
              certify to Prelim in writing that Customer has complied with the
              requirements of this Section 9.4(b)
            </span>
            <a id="id.3whwml4"></a>
            <span className="c4 c1">;</span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              notwithstanding anything to the contrary in this Agreement, Prelim
              may retain Customer Data in its backups, archives, and disaster
              recovery systems until such Customer Data is deleted in the
              ordinary course and all such Customer Data will remain subject to
              all confidentiality, security, and other applicable requirements
              of this Agreement;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              Prelim may disable all Customer access to the Services and Prelim
              Materials;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              Prelim will make all Customer Data available to Customer for
              electronic retrieval for a period of 30 days, but thereafter
              Prelim may, but is not obligated to, delete stored Customer Data;
              and{" "}
            </span>
          </li>
          <li className="c6 c7">
            <span className="c5 c1">
              if Prelim terminates this Agreement pursuant to Section 9.3(a) or
              Section 9.3(b), Customer will pay in full for the Services up to
              and including the last day on which the Services are provided
            </span>
            <a id="id.2bn6wsx"></a>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1" start="5">
          <li className="c0 c9">
            <span className="c2 c1">Surviving Terms</span>
            <span className="c5 c1">
              . The provisions of Sections 1, 3.3, 5, 7, 8, 10.4, 11, and 12,
              this Section 9.5, and any other term of this Agreement that by its
              nature operates beyond termination or expiration, will survive and
              continue in full force and effect after any termination or
              expiration of this A
            </span>
            <a id="id.qsh70q"></a>
            <span className="c4 c1">greement.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="10">
          <li className="c0 c10">
            <span className="c2 c1">Representations and Warranties</span>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c0 c9">
            <span className="c2 c1">Mutual Representations and Warranties</span>
            <span className="c4 c1">
              . Each Party represents and warrants to the other Party that:{" "}
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c6 c7">
            <span className="c4 c1">
              it is duly organized, validly existing and in good standing as a
              corporation or other entity under the Laws of the jurisdiction of
              its incorporation or other organization;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              it has the full right, power and authority to enter into and
              perform its obligations and grant the rights, licenses, consents
              and authorizations it grants or is required to grant under this
              Agreement;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              the execution of this Agreement by its Representative whose
              signature is set forth at the end of this Agreement has been duly
              authorized by all necessary corporate or organizational action of
              such Party; and
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              when executed and delivered by both Parties, this Agreement will
              constitute the legal, valid and binding obligation of such Party,
              enforceable against such Party in accordance with its terms.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1" start="2">
          <li className="c0 c9">
            <span className="c2 c1">
              Additional Prelim Representations, Warranties, and Covenants
            </span>
            <span className="c4 c1">
              . Prelim represents, warrants, and covenants to Customer that
              Prelim will perform the Services using personnel of required
              skill, experience, and qualifications and in a professional and
              workmanlike manner in accordance with generally recognized
              industry standards for similar services and will devote adequate
              resources to meet its obligations under this Agreement.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">
              Additional Customer Representations, Warranties and Covenants
            </span>
            <span className="c4 c1">
              . Customer represents, warrants and covenants to Prelim that
              Customer owns or otherwise has and will have the necessary rights
              and consents in and relating to the Customer Data so that, as
              received by Prelim and Processed in accordance with this
              Agreement, they do not and will not infringe, misappropriate or
              otherwise violate any Intellectual Property Rights, or any privacy
              or other right of any third party or violate any applicable Law.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">DISCLAIMER OF WARRANTIES</span>
            <span className="c5 c1">
              . EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN SECTION 10.1 and
              SECTION 10.2, ALL SERVICES AND PRELIM MATERIALS ARE PROVIDED
              &ldquo;AS IS&rdquo; AND PRELIM HEREBY DISCLAIMS ALL WARRANTIES,
              WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHER, AND PRELIM
              SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND
              ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE
              PRACTICE. WITHOUT LIMITING THE FOREGOING, PRELIM MAKES NO WARRANTY
              OF ANY KIND THAT THE SERVICES OR PRELIM MATERIALS, OR ANY PRODUCTS
              OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER&#39;S OR ANY
              OTHER PERSON&#39;S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION,
              ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY
              SOFTWARE, SYSTEM, OR OTHER SERVICES OR BE SECURE, ACCURATE,
              COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. PRELIM EXPRESSLY
              DISCLAIMS AND IS NOT RESPONSIBLE FOR ALL DECISIONS OR ANALYSIS
              MADE BY ANY PERSON BASED UPON THE USE OR RESULTS OF THE SERVICES
              OR PRELIM MATERIALS. ALL THIRD-PARTY MATERIALS ARE PROVIDED
              &ldquo;AS IS&rdquo; AND ANY REPRESENTATION OR WARRANTY OF OR
              CONCERNING ANY THIRD PARTY MATERIALS IS STRICTLY BETWEEN CUSTOMER
              AND THE THIRD-PARTY OWNER OR DI
            </span>
            <a id="id.3as4poj"></a>
            <span className="c4 c1">
              STRIBUTOR OF THE THIRD-PARTY MATERIALS.
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="11">
          <li className="c0 c10">
            <span className="c2 c1">Indemnification</span>
            <a id="id.1pxezwc"></a>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c12 c9">
            <span className="c2 c1">Prelim Indemnification</span>
            <span className="c5 c1">
              . Prelim will indemnify, defend, and hold harmless Customer and
              its officers, directors, employees, agents, successors, and
              permitted assigns (each, a &ldquo;
            </span>
            <span className="c8 c5 c1">Customer Indemnitee</span>
            <span className="c5 c1">
              &rdquo;) from and against any and all Losses incurred by Customer
              Indemnitee arising out of or relating to any claim, suit, action
              or proceeding (each, an &ldquo;
            </span>
            <span className="c8 c5 c1">Action</span>
            <span className="c4 c1">
              &rdquo;) by a third party (other than an Affiliate of any Customer
              Indemnitee) alleging that Customer&#39;s use of the Services
              (excluding Customer Data and Third Party Materials) in compliance
              with this Agreement infringes a United States Intellectual
              Property Right. The foregoing obligation does not apply to any
              Action or Losses arising out of or relating to any:{" "}
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c6 c7">
            <span className="c4 c1">
              access to or use of the Services or Prelim Materials in
              combination with any hardware, system, software, network, or other
              materials or service not provided or authorized in writing by
              Prelim;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              modification of the Services or Prelim Materials other than: (i)
              by or on behalf of Prelim; or (ii) with Prelim&#39;s written
              approval in accordance with Prelim&#39;s written specifications;
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              failure to timely implement any modifications, upgrades,
              replacements, or enhancements made available to Customer by or on
              behalf of Prelim; or
            </span>
          </li>
          <li className="c12 c11">
            <span className="c5 c1">
              act, omission or other matter described in Section 11.2(a),
              Section 11.2(b), Section 11.2(c) or Section 11.2(d), whether or
              not the same results in any Action against or Losses by any Prelim
            </span>
            <a id="id.49x2ik5"></a>
            <span className="c4 c1">&nbsp;Indemnitee.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1" start="2">
          <li className="c0 c9">
            <span className="c2 c1">Customer Indemnification</span>
            <span className="c5 c1">
              . Customer will indemnify, defend, and hold harmless Prelim and
              its Subcontractors and Affiliates, and each of its and their
              respective officers, directors, employees, agents, successors, and
              assigns (each, a &ldquo;
            </span>
            <span className="c8 c5 c1">Prelim Indemnitee</span>
            <span className="c5 c1">
              &rdquo;) from and against any and all Losses incurred by such
              Prelim Indemnitee in connection with any Action by a third party
              (other than an Affiliate of any Prelim Indemnitee) that arises out
              of or relates to any
            </span>
            <a id="id.2p2csry"></a>
            <span className="c4 c1">: </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c6 c7">
            <span className="c5 c1">
              Customer Data, including any Processing of Customer Data by or on
              behalf of Prelim in accordance w
            </span>
            <a id="id.147n2zr"></a>
            <span className="c4 c1">ith this Agreement;</span>
          </li>
          <li className="c6 c7">
            <span className="c5 c1">
              any other materials or information (including any Personal
              Information, documents, data, specifications, software, content,
              or technology) provided by or on behalf of Customer, including
              Prelim&#39;s compliance with any specifications or directions
              provided by or on behalf of Customer to the extent prepared
              without any contribution by Prelim
            </span>
            <a id="id.3o7alnk"></a>
            <span className="c4 c1">; </span>
          </li>
          <li className="c6 c7">
            <span className="c5 c1">
              allegation of facts that, if true, would constitute Customer&#39;s
              breach of any of its representations, warranties, covenants, or
              obligations under this{" "}
            </span>
            <a id="id.23ckvvd"></a>
            <span className="c4 c1">Agreement; or</span>
          </li>
          <li className="c6 c7">
            <span className="c5 c1">
              negligence or more culpable act or omission, or fraud (including
              recklessness or willful misconduct) by Customer or any third party
              on behalf of Customer in connection with this Agreeme
            </span>
            <a id="id.ihv636"></a>
            <span className="c4 c1">nt. </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1" start="3">
          <li className="c0 c9">
            <span className="c2 c1">Indemnification Procedure</span>
            <span className="c5 c1">
              . Each Party will promptly notify the other Party in writing of
              any Action for which such party believes it is entitled to be
              indemnified pursuant to Section 11.1 or Section 11.2. The Party
              seeking indemnification (the &ldquo;
            </span>
            <span className="c8 c5 c1">Indemnitee</span>
            <span className="c5 c1">
              &rdquo;) will cooperate with the other Party (the &ldquo;
            </span>
            <span className="c8 c5 c1">Indemnitor</span>
            <span className="c4 c1">
              &rdquo;) at the Indemnitor&#39;s sole cost and expense. The
              Indemnitor will immediately take control of the defense and
              investigation of such Action and will employ counsel of its choice
              to handle and defend the same, at the Indemnitor&#39;s sole cost
              and expense. The Indemnitee&#39;s failure to perform any
              obligations under this Section 11.3 will not relieve the
              Indemnitor of its obligations under this Section 11 except to the
              extent that the Indemnitor can demonstrate that it has been
              materially prejudiced as a result of such failure. The Indemnitee
              may participate in and observe the proceedings at its own cost and
              expense with counsel of its own choosing.
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Mitigation</span>
            <span className="c4 c1">
              . If any of the Services or Prelim Materials are, or in
              Prelim&#39;s opinion are likely to be, claimed to infringe,
              misappropriate, or otherwise violate any third-party Intellectual
              Property Right, or if Customer&#39;s use of the Services or Prelim
              Materials is enjoined or threatened to be enjoined, Prelim may, at
              its option and sole cost and expense:{" "}
            </span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-2 start" start="1">
          <li className="c6 c7">
            <span className="c4 c1">
              obtain the right for Customer to continue to use the Services and
              Prelim Materials as materially contemplated by this Agreement;{" "}
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              modify or replace the Services and Prelim Materials, in whole or
              in part, to seek to make the Services and Prelim Materials (as so
              modified or replaced) non-infringing, while providing materially
              equivalent features and functionality, in which case such
              modifications or replacements will constitute Services and Prelim
              Materials, as applicable, under this Agreement; or{" "}
            </span>
          </li>
          <li className="c6 c7">
            <span className="c4 c1">
              by written notice to Customer, terminate this Agreement and
              require Customer to immediately cease any use of the Services and
              Prelim Materials.
            </span>
          </li>
        </ol>
        <p className="c7 c17">
          <span className="c5 c1">
            THIS SECTION 11 SETS FORTH CUSTOMER&#39;S SOLE REMEDIES AND
            PRELIM&#39;S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL,
            THREATENED, OR ALLEGED CLAIMS THAT THIS AGREEMENT OR ANY SUBJECT
            MATTER HEREOF (INCLUDING THE SERVICES AND PRELIM MATERIALS)
            INFRINGES, MISAPPROPRIATES, OR OTHERWISE VIOLATES ANY THIRD PARTY
            INTELLECTUAL PROPERTY RIGHT
          </span>
          <a id="id.32hioqz"></a>
          <span className="c4 c1">. </span>
        </p>
        <ol className="c3 lst-kix_list_2-0" start="12">
          <li className="c0 c10">
            <span className="c2 c1">Limitations of Liability</span>
            <a id="id.1hmsyys"></a>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c12 c9">
            <span className="c2 c1">EXCLUSION OF DAMAGES</span>
            <span className="c4 c1">
              . NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY
              INJURY OF A PERSON, IN NO EVENT WILL PRELIM OR ANY OF ITS
              LICENSORS, SERVICE PROVIDERS, OR SUPPLIERS BE LIABLE UNDER OR IN
              CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY
              LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
              (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY:
              (a) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE, OR PROFIT OR
              DIMINUTION IN VALUE; (B) IMPAIRMENT, INABILITY TO USE OR LOSS,
              INTERRUPTION, OR DELAY OF THE SERVICES, OTHER THAN FOR THE
              ISSUANCE OF ANY APPLICABLE SERVICE CREDITS PURSUANT TO THE SERVICE
              LEVEL AGREEMENT, IF ANY; (C) LOSS, DAMAGE, CORRUPTION OR RECOVERY
              OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY; (D) FOR ANY MATTER
              BEYOND PRELIM&rsquo;S REASONABLE CONTROL, OR (D) CONSEQUENTIAL,
              INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED OR PUNITIVE
              DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE
              POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES
              WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY
              AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">CAP ON MONETARY LIABILITY</span>
            <span className="c5 c1">
              . IN NO EVENT WILL THE COLLECTIVE AGGREGATE LIABILITY OF PRELIM
              AND ITS LICENSORS, SERVICE PROVIDERS, AND SUPPLIERS UNDER OR IN
              CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY
              LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT,
              INCLUDING NEGLIGENCE, STRICT LIABILITY AND OTHERWISE, EXCEED AN
              AMOUNT EQUAL TO THE FEES ACTUALLY RECEIVED BY PRELIM IN THE TWELVE
              MONTHS PRECEDING THE DATE OF THE OCCURRENCE OR EVENT UNDERLYING
              THE{" "}
            </span>
            <a id="id.41mghml"></a>
            <span className="c4 c1">CLAIM.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-0" start="13">
          <li className="c0 c10">
            <span className="c2 c1">Miscellaneous</span>
            <span className="c4 c1">.</span>
          </li>
        </ol>
        <ol className="c3 lst-kix_list_2-1 start" start="1">
          <li className="c9 c12">
            <span className="c2 c1">Force Majeure</span>
            <span className="c5 c1">
              . Neither Party will be liable or responsible to the other Party,
              nor be deemed to have defaulted under or breached this Agreement,
              for any failure or delay in fulfilling or performing any term of
              this Agreement, when and to the extent such failure or delay is
              caused by acts of God, flood, fire, &nbsp;explosion, war,
              terrorism, invasion, riot, other civil unrest, embargoes or
              blockades in effect on or after the date of this Agreement, or
              national or regional emergency, in each case, provided that such
              event is outside the reasonable control of the affected Party
              (&ldquo;
            </span>
            <span className="c8 c5 c1">Force Majeure Event</span>
            <span className="c4 c1">&rdquo;).</span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Relationship of the Parties</span>
            <span className="c4 c1">
              . The relationship between the Parties is that of independent
              contractors. Nothing contained in this Agreement will be construed
              as creating any agency, partnership, joint venture, or other form
              of joint enterprise, employment, or fiduciary relationship between
              the Parties, and neither Party will have authority to contract for
              or bind the other Party in any manner whatsoever. Prelim retains
              absolute discretion in the manner and means of carrying out the
              Services, and Customer will have no obligation to direct or
              control the working conditions of Prelim&#39;s employees or
              Subcontractors.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Public Announcements</span>
            <span className="c5 c1">
              . The Parties shall work together in good faith to issue at least
              one mutually agreed upon press release within 90 days of the
              Effective Date, and Customer otherwise agrees to reasonably
              cooperate with Prelim
            </span>
            <a id="id.2grqrue"></a>
            <span className="c4 c1">
              &nbsp;to serve as a reference account upon request.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Notices</span>
            <span className="c4 c1">
              . All notices, requests, consents, claims, demands, waivers, and
              other communications hereunder will be in writing and addressed to
              the Parties in the Order Form (or as otherwise specified by a
              Party in a notice given in accordance with this Section). Notices
              sent in accordance with this Section will be deemed effectively
              given: (a) when received, if delivered by hand (with written
              confirmation of receipt); (b) when received, if sent by a
              nationally recognized overnight courier (receipt requested); (c)
              on the date sent by e-mail, if sent during normal business hours
              of the recipient with confirmed receipt, and on the next business
              day, if sent after normal business hours of the recipient with
              confirmed receipt.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Entire Agreement</span>
            <span className="c5 c1">
              . This Agreement, together with any other documents incorporated
              herein by reference, constitutes the sole and entire agreement of
              the Parties with respect to the subject matter of this Agreement
              and supersedes all prior and contemporaneous understandings,
              agreements, representations, and warranties, both written and
              oral, with respect to such subject matter. No terms or conditions
              of any purchase order or similar document provided by Customer to
              Prelim will constitute a part or amendment of this Agreement or be
              binding on Prelim for any purpose unless expressly accepted by
              Prelim in writin
            </span>
            <a id="id.vx1227"></a>
            <span className="c4 c1">g. </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Assignment</span>
            <span className="c4 c1">
              . Customer may not assign or otherwise transfer any of its rights,
              or delegate or otherwise transfer any of its obligations or
              performance, under this Agreement, in each case whether
              voluntarily, involuntarily, by operation of law or otherwise,
              without Prelim&#39;s prior written consent. Prelim may, without
              Customer&#39;s consent, assign this Agreement to an Affiliate or
              pursuant to a merger, consolidation, reorganization, change of
              control, or a sale of all or substantially all of such party&#39;s
              assets or stock. This Agreement is binding upon and inures to the
              benefit of the parties hereto and their respective permitted
              successors and assigns.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Amendment and Modification; Waiver</span>
            <span className="c4 c1">
              . No amendment to or modification of this Agreement is effective
              unless it is in writing, identified as an amendment to this
              Agreement and signed by an authorized representative of both
              parties. No waiver by any party of any of the provisions hereof
              will be effective unless explicitly set forth in writing and
              signed by the party so waiving.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">Severability</span>
            <span className="c4 c1">
              . If any term or provision of this Agreement is invalid, illegal,
              or unenforceable in any jurisdiction, such invalidity, illegality,
              or unenforceability will not affect any other term or provision of
              this Agreement or invalidate or render unenforceable such term or
              provision in any other jurisdiction.
            </span>
          </li>
          <li className="c12 c9">
            <span className="c2 c1">
              Governing Law; Submission to Jurisdiction; Attorneys&#39; Fees.{" "}
            </span>
            <span className="c4 c1">
              This Agreement and all related documents are governed by, and
              construed in accordance with, the laws of the State of California,
              without regard to the conflict of laws provisions thereof to the
              extent such principles or rules would require or permit the
              application of the laws of any jurisdiction other than those of
              the State of California. The sole and exclusive jurisdiction and
              venue for any litigation arising out of this Agreement shall be an
              appropriate federal or state court in the State of California, and
              the Parties irrevocably consent to the personal jurisdiction of
              such courts. &nbsp;Each Party hereby waives any objection or
              defense of personal jurisdiction or forum non-conveniens it may
              otherwise possess. In any action or proceeding to enforce rights
              under this Agreement, the prevailing Party will be entitled to
              recover costs and attorneys&#39; fees.{" "}
            </span>
          </li>
          <li className="c0 c9">
            <span className="c2 c1">Equitable Relief</span>
            <span className="c4 c1">
              . Each Party acknowledges and agrees that a breach or threatened
              breach by such party of any of its obligations under Section 8 or,
              in the case of Customer breach of Section 3.3, Section 4.3, or
              Section 7.1 may cause the other Party irreparable harm for which
              monetary damages would not be an adequate remedy and agrees that,
              in the event of such breach or threatened breach, the other Party
              will be entitled to seek equitable relief, including a restraining
              order, an injunction, specific performance, and any other relief
              that may be available from any court, without any requirement to
              post a bond or other security, or to prove actual damages or that
              monetary damages are not an adequate remedy. Such remedies are not
              exclusive and are in addition to all other remedies that may be
              available at law, in equity or otherwise.
            </span>
          </li>
          <li className="c13 c9 c7">
            <span className="c2 c1">Counterparts</span>
            <span className="c5 c1">
              . This Agreement may be executed in counterparts, each of which
              will be deemed an original, but all of which together will be
              deemed to be one and the same agreement. A signed copy of this
              Agreement delivered by facsimile, e-mail, or other means of
              electronic transmission will be deemed to have the same legal
              effect as delivery of an original signed copy of this Agreement.
            </span>
          </li>
        </ol>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
