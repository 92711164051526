import React from "react";
import HelmetHeader from "./helmetHeader";
import "../css/integrations.css";
import Header from "./header";
import Footer from "./footer";
import CallToAction from "./call_to_action";
import "../css/event.css";
import RegistrationImg from "../img/registration.svg";
import EventProfile1 from "../img/event_profile_1.png";
import EventProfile2 from "../img/event_profile_2.png";
import EventProfile3 from "../img/event_profile_3.png";
import EventProfile4 from "../img/event_profile_4.png";
import EventCo1 from "../img/event_co_1.png";
import EventCo2 from "../img/event_co_2.png";
import EventCo3 from "../img/event_co_3.png";
import EventCo4 from "../img/event_co_4.png";
import TopLeftAgenda from "../img/top_left_agenda.svg";
import BottomLeftAgenda from "../img/bottom_left_agenda.svg";
import TopRightAgenda from "../img/top_right_agenda.svg";
import BottomRightAgenda from "../img/bottom_right_agenda.svg";

function DescriptionSection() {
  return (
    <div className="event_description_section">
      <div className="event_section_content">
        <div className="event_description_section_box">
          <div className="event_description_box_left">
            <img className="event_description_img" src={RegistrationImg} />
          </div>
          <div className="event_description_box_right">
            <div className="event_description_section_category">Webinar</div>
            <div className="event_description_section_title_1">
              (re)Defining Digital Transformation
            </div>
            <div className="event_description_section_title_2">
              {`moving beyond the why's to the how's`}
            </div>
            <div className="event_description_section_date">
              Friday, March 25 at 11:00 a.m. PST | 2:00 p.m. EST | 7:00 p.m. GMT
            </div>
            <div className="event_description_section_para">
              With the pandemic, the digital transformation window for customer
              engagement is closing faster than ever. What are your strategies
              and how are you executing them? Learn, engage and collaborate with
              your peers!
            </div>
            <div className="event_description_section_button_container">
              <button
                className="event_description_section_button"
                onClick={() =>
                  (window.location.href =
                    "https://us02web.zoom.us/webinar/register/WN_Z1PBAMpmTw-RB9X_t4yNGQ")
                }
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PeopleSection() {
  const profiles = [
    {
      img: EventProfile1,
      co_img: EventCo1,
      name: "Bria Bailey",
      title: "Vice President, Corporate Development",
    },
    {
      img: EventProfile2,
      co_img: EventCo2,
      name: "Jonathan Bresler",
      title: "Managing Director, Portfolio Manager",
    },
    {
      img: EventProfile3,
      co_img: EventCo3,
      name: "Jennifer Taylor",
      title: "Head of Digital Banking",
    },
    {
      img: EventProfile4,
      co_img: EventCo4,
      name: "John Vander Velde",
      title: "Chief Information Officer",
    },
  ];
  return (
    <div className="event_people_section">
      <div className="event_section_content">
        <div className="event_people_section_box">
          <div className="event_people_section_category">Panel Speakers</div>
          <div className="event_people_section_title">
            Strategic thinking with experienced leaders
          </div>
          <div className="event_people_section_para_1">
            {`Listen to your peers' strategies and approaches with internal & external actors.`}
          </div>
          <div className="event_people_section_para_2">
            Learn from their successes and challenges. Sharpen your message.
          </div>
        </div>
        <div className="event_people_section_people">
          {profiles.map((profile) => {
            return (
              <div key={profile.name} className="event_people_profile">
                <div className="event_people_profile_container">
                  <div className="event_people_profile_bg" />
                  <div className="event_people_profile_img_container">
                    <img
                      className="event_people_profile_img"
                      src={profile.img}
                    />
                  </div>
                  <div className="event_people_co_bg" />
                  <div className="event_people_co_img_container">
                    <img className="event_people_co_img" src={profile.co_img} />
                  </div>
                </div>
                <div className="event_people_profile_name">{profile.name}</div>
                <div className="event_people_profile_title">
                  {profile.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function AgendaSection() {
  return (
    <div className="event_agenda_section">
      <img src={TopLeftAgenda} className="event_agenda_top_left_img" />
      <img src={BottomLeftAgenda} className="event_agenda_bottom_left_img" />
      <img src={TopRightAgenda} className="event_agenda_top_right_img" />
      <img src={BottomRightAgenda} className="event_agenda_bottom_right_img" />
      <div className="event_section_content">
        <div className="event_agenda_section_box">
          <div className="event_agenda_section_title">Agenda</div>
          <div className="event_agenda_section_date">
            11:00 a.m. - 11:15 a.m. PST
          </div>
          <div className="event_agenda_section_item">
            Prelim NEW Product & Feature Demo: Heang Chang
          </div>
          <div className="event_agenda_section_date">
            11:15 a.m. - 11:30 a.m. PST
          </div>
          <div className="event_agenda_section_item">
            Pacific Western Bank: How we’ve been using Prelim
          </div>
          <div className="event_agenda_section_date">
            11:30 a.m. - 11:50 a.m. PST
          </div>
          <div className="event_agenda_section_item">
            Panel - Digital Transformation: Present and Future State
          </div>
          <div className="event_agenda_section_date">
            11:50 a.m. - 12:00 p.m. PST
          </div>
          <div className="event_agenda_section_item">Q&A Session</div>
        </div>
      </div>
    </div>
  );
}

function EventsRegistrationMarch25() {
  return (
    <div>
      <HelmetHeader />
      <Header />
      <DescriptionSection />
      <PeopleSection />
      <AgendaSection />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default EventsRegistrationMarch25;
