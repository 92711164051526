import React from "react";
import "../css/footer.css";
import LogoColor from "../svgs/white_logo 2.svg";
import SvgLinkedin from "./svg_linkedin";
import SvgX from "./svg_x";
import SvgYoutube from "./svg_youtube";

function Footer() {
  return (
    <div className="footer-root">
      <div className="footer-content">
        <div className="footer-links">
          <div className="footer-company-information">
            <img src={LogoColor} className="footer-logo" alt="Prelim Logo" />
            <div className="footer-company-address-container">
              <a className="footer-list-address-title">San Francisco HQ </a>
              <a className="footer-list-address-text">
                220 Montgomery Street #1950,
              </a>
              <a className="footer-list-address-text">
                San Francisco, CA 94104
              </a>
            </div>
          </div>
          <div className="footer-list-container">
            <div className="footer-list">
              <div className="footer-list-header">Solutions</div>
              <a className="footer-list-item" href="/business-account-opening">
                Business Account Opening
              </a>
              <a className="footer-list-item" href="/consumer-account-opening">
                Consumer Account Opening
              </a>
            </div>
            <div className="footer-list">
              <div className="footer-list-header">Platform</div>
              <a className="footer-list-item" href="/integrations">
                Integrations
              </a>
              <a className="footer-list-item" href="https://updates.prelim.com">
                Updates
              </a>
              <a
                className="footer-list-item"
                href="https://features.prelim.com"
              >
                Requests
              </a>
              <a className="footer-list-item" href="https://status.prelim.com">
                Status
              </a>
            </div>
            <div className="footer-list">
              <div className="footer-list-header">Academy</div>
              <a
                className="footer-list-item"
                href="https://docs.prelim.com/reference/getting-started-with-your-api-1"
              >
                API Documentation
              </a>
              <a className="footer-list-item" href="https://docs.prelim.com">
                Learning Center
              </a>
            </div>
            <div className="footer-list">
              <div className="footer-list-header">Resources</div>
              <a className="footer-list-item" href="https://support.prelim.com">
                Support
              </a>
              <a className="footer-list-item" href="/blog">
                Blog
              </a>
              <a className="footer-list-item" href="/customer-stories">
                Customer Stories
              </a>
            </div>
            <div className="footer-list">
              <div className="footer-list-header">Company</div>
              <a className="footer-list-item" href="/about">
                About
              </a>
              <a className="footer-list-item" href="/careers">
                Careers
              </a>
              <a className="footer-list-item" href="/news">
                Press
              </a>
              <a className="footer-list-item" href="/legal/terms-of-service">
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-bottom-left">&copy; Prelim</div>
          <div className="footer-bottom-right">
            <a
              className="footer-bottom-right-icon"
              href="https://www.youtube.com/@prelimsolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgYoutube />
            </a>
            <a
              className="footer-bottom-right-icon"
              href="https://www.linkedin.com/company/prelimsolutions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgLinkedin />
            </a>
            <a
              className="footer-bottom-right-icon"
              href="https://twitter.com/prelimsolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgX />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
