const ROOT_URL = "https://api.lever.co";

function getPostings() {
  return fetch(ROOT_URL + `/v0/postings/prelim?mode=json`)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

module.exports = {
  getPostings,
};
