import React from "react";

const Svg = (props) => (
  <svg
    width="27"
    height="31"
    viewBox="0 0 27 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.76171 2.5625C8.98141 2.5625 9.97934 3.6875 9.97934 5.0625C9.97934 6.4375 8.98141 7.5625 7.76171 7.5625C6.54202 7.5625 5.54408 6.4375 5.54408 5.0625C5.54408 3.6875 6.54202 2.5625 7.76171 2.5625ZM17.7411 11.9375C17.7411 12.975 18.484 13.8125 19.4043 13.8125C20.3246 13.8125 21.0675 12.975 21.0675 11.9375C21.0675 10.9 20.3246 10.0625 19.4043 10.0625C18.484 10.0625 17.7411 10.9 17.7411 11.9375ZM21.0675 15.0625H17.9185C17.2754 15.075 16.6544 15.4625 16.3107 16.1375L15.2906 17.7875L11.3321 10.0625C10.9218 9.275 10.19 8.825 9.43603 8.8125H6.09849C4.87879 8.8125 3.88086 9.9375 3.88086 11.3125V18.8125H5.54408V27.5625H11.0882V14.575L13.8935 20.0625H16.3329L17.1866 18.6875V27.5625H21.6219V21.3125H22.7307V16.9375C22.7307 15.9125 21.9878 15.0625 21.0675 15.0625Z"
      fill="#6578FF"
    />
  </svg>
);

export default Svg;
