import React from "react";

const Svg = (props) => (
  <svg
    width="1155"
    height="546"
    viewBox="0 0 1155 546"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_2059_5112)">
      <path
        d="M696.422 466.566C389.541 563.365 139.908 454.544 7.75022 306.743L1023.63 128.467C1056.35 196.147 1003.3 369.767 696.422 466.566Z"
        fill="url(#paint0_linear_2059_5112)"
      />
    </g>
    <g filter="url(#filter1_f_2059_5112)">
      <path
        d="M836.902 354.157C684.675 252.854 728.105 103.945 787.732 -27.6577L1154.39 353.115C1119.09 400.13 989.13 455.46 836.902 354.157Z"
        fill="url(#paint1_linear_2059_5112)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2059_5112"
        x="3.75"
        y="124.467"
        width="1032.32"
        height="382.837"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2059_5112"
        />
      </filter>
      <filter
        id="filter1_f_2059_5112"
        x="728.857"
        y="-31.6577"
        width="429.533"
        height="446.799"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2059_5112"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2059_5112"
        x1="712.689"
        y1="576.866"
        x2="613.365"
        y2="271.618"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#E5FF45" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2059_5112"
        x1="768.713"
        y1="413.122"
        x2="906.108"
        y2="200.951"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#FF9789" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
