import React from "react";
import HelmetHeader from "./helmetHeader";
import "../css/blog.css";
import Header from "./header";
import Footer from "./footer";
import CallToAction from "./call_to_action";
import contentfulActions from "../actions/contentful";
import _ from "lodash";
import dateUtils from "../utils/date";

function Hero({ entries }) {
  var article =
    _.first(_.orderBy(entries.items, "fields.publishDate", "desc")) || {};
  var articleTitle = _.get(article, "fields.title");
  var articleFeatureImageUrl = _.get(
    _.find(_.get(entries, "includes.Asset"), {
      sys: { id: _.get(article, "fields.featureImage.sys.id") },
    }),
    "fields.file.url"
  );
  var articleDescription = _.get(article, "fields.description");

  var url = "/blog/" + _.get(article, "fields.slug");

  return (
    <div className="blog-hero">
      <div className="blog-hero-content">
        <div className="blog-hero-article">
          <div className="blog-hero-content-lhs">
            <div className="blog-hero-subtitle">Featured Post</div>
            <div>
              <div className="blog-hero-title">{articleTitle}</div>
              <div className="blog-hero-article-description">
                {articleDescription}
              </div>
            </div>
            <a href={url} className="blog-hero-button">
              Read more →
            </a>
          </div>
          <div className="blog-hero-content-rhs">
            <img
              className="blog-hero-image"
              src={articleFeatureImageUrl + "?fit=thumb&f=top_left&h=207&w=368"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Articles({ entries }) {
  return (
    <div className="blog-articles">
      <div className="blog-articles-content">
        <div className="blog-articles-title">News</div>
        <div className="blog-articles-list">
          {_.map(
            _.orderBy(entries.items, "fields.publishDate", "desc"),
            (entry) => {
              var articleFeatureImageUrl = _.get(
                _.find(_.get(entries, "includes.Asset"), {
                  sys: { id: _.get(entry, "fields.featureImage.sys.id") },
                }),
                "fields.file.url"
              );
              var author = _.get(
                _.find(_.get(entries, "includes.Entry"), {
                  sys: { id: _.get(entry, "fields.author.sys.id") },
                }),
                "fields.name"
              );
              var url = "/blog/" + _.get(entry, "fields.slug");
              return (
                <div className="blog-articles-article">
                  <div className="blog-articles-article-image-container">
                    <img
                      className="blog-articles-article-image"
                      src={
                        articleFeatureImageUrl +
                        "?fit=thumb&f=top_left&h=207&w=368"
                      }
                    />
                  </div>
                  <div className="blog-articles-article-title">
                    {entry.fields.title}
                  </div>
                  <div className="blog-articles-article-date">
                    {dateUtils.getDisplayDate(entry.fields.publishDate)}
                  </div>
                  <div className="blog-articles-article-author">{author}</div>
                  <a href={url} className="blog-articles-article-link">
                    Read more
                  </a>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

function News() {
  var [entries, setEntries] = React.useState({});
  React.useEffect(() => {
    contentfulActions.getEntries({ content_type: "blogPost" }).then((res) => {
      setEntries(res);
    });
  }, []);

  var title = "Prelim Blog | DAO Platform & Customer Journey Insights";
  var description =
    "The Prelim Blog is a source of news on banking and how to navigate the digitization of banking.";
  if (_.isEmpty(entries)) {
    return <></>;
  }
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero entries={entries} />
      <Articles entries={entries} />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default News;
