import React from "react";

const Svg = (props) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.75 8.1875H21.25C21.25 4.7375 18.45 1.9375 15 1.9375C11.55 1.9375 8.75 4.7375 8.75 8.1875H6.25C4.875 8.1875 3.75 9.3125 3.75 10.6875V25.6875C3.75 27.0625 4.875 28.1875 6.25 28.1875H23.75C25.125 28.1875 26.25 27.0625 26.25 25.6875V10.6875C26.25 9.3125 25.125 8.1875 23.75 8.1875ZM15 4.4375C17.075 4.4375 18.75 6.1125 18.75 8.1875H11.25C11.25 6.1125 12.925 4.4375 15 4.4375ZM23.75 25.6875H6.25V10.6875H23.75V25.6875ZM15 15.6875C12.925 15.6875 11.25 14.0125 11.25 11.9375H8.75C8.75 15.3875 11.55 18.1875 15 18.1875C18.45 18.1875 21.25 15.3875 21.25 11.9375H18.75C18.75 14.0125 17.075 15.6875 15 15.6875Z"
      fill="#6578FF"
    />
  </svg>
);

export default Svg;
