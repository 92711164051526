import React from "react";
import HelmetHeader from "./helmetHeader";
import "../css/customer_stories.css";
import Header from "./header";
import Footer from "./footer";
import CallToAction from "./call_to_action";

function Hero() {
  return <div className="blog-hero"></div>;
}

function CustomerStories() {
  return (
    <div>
      <HelmetHeader />
      <Header />
      <Hero />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default CustomerStories;
