import React from "react";

const Svg = (props) => (
  <svg
    width="1148"
    height="962"
    viewBox="0 0 1148 962"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_f_2072_5422)">
      <path
        d="M177.062 464.642C177.884 448.841 210.831 444.539 263.719 448.888L1134.05 290.666C1070.49 336.581 943.208 481.341 870.036 609.688C1076.27 693.866 1189.34 787.436 943.558 828.321C776.797 856.063 794.867 741.535 870.036 609.688C676.908 530.859 402.079 460.267 263.719 448.888L177.062 464.642Z"
        fill="url(#paint0_linear_2072_5422)"
      />
    </g>
    <g filter="url(#filter1_f_2072_5422)">
      <path
        d="M50.6907 140.997C78.9998 155.989 103.4 171.423 124.358 187.182L941.289 699.352C941.288 788.172 377.76 848.644 201.632 699.352C115.979 626.749 383.583 382.109 124.358 187.182L50.6907 140.997Z"
        fill="url(#paint1_linear_2072_5422)"
      />
    </g>
    <g filter="url(#filter2_f_2072_5422)">
      <path
        d="M314.838 538.139C133.069 426.928 27.8804 309.61 111.877 145.905L709.804 513.158C662.385 572.686 496.608 649.35 314.838 538.139Z"
        fill="url(#paint2_linear_2072_5422)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2072_5422"
        x="173.062"
        y="286.667"
        width="964.984"
        height="549.836"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2072_5422"
        />
      </filter>
      <filter
        id="filter1_f_2072_5422"
        x="46.6914"
        y="136.997"
        width="898.598"
        height="657.043"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2072_5422"
        />
      </filter>
      <filter
        id="filter2_f_2072_5422"
        x="78.5332"
        y="141.905"
        width="635.271"
        height="456.903"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2072_5422"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2072_5422"
        x1="777.514"
        y1="1089.99"
        x2="539.799"
        y2="591.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#E5FF45" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2072_5422"
        x1="155.757"
        y1="658.908"
        x2="372.584"
        y2="331.889"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#FF9789" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2072_5422"
        x1="225.619"
        y1="614.584"
        x2="391.348"
        y2="335.114"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#4E63F8" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
