import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import _ from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import contentfulActions from "../actions/contentful";
import hubspotActions from "../actions/hubspot";
import "../css/blog.css";
import "../css/blog_post.css";
import dateUtils from "../utils/date";
import CallToAction from "./call_to_action";
import Footer from "./footer";
import Header from "./header";
import HelmetHeader from "./helmetHeader";
import SvgLinkedIn from "./svg_linkedin";
import SvgX from "./svg_x_black";
const HUBSPOT_FORM_ID = "27ddf5cb-3a99-4c2b-9301-c88dfad1e5bd";

function Hero({ entries }) {
  var article =
    _.first(_.orderBy(entries.items, "fields.publishDate", "desc")) || {};
  var publishDate = _.get(article, "fields.publishDate");
  var articleTitle = _.get(article, "fields.title");
  var articleFeatureImageUrl = _.get(
    _.find(_.get(entries, "includes.Asset"), {
      sys: { id: _.get(article, "fields.featureImage.sys.id") },
    }),
    "fields.file.url"
  );
  var author =
    _.find(_.get(entries, "includes.Entry"), {
      sys: { id: _.get(article, "fields.author.sys.id") },
    }) || {};
  var authorName = _.get(author, "fields.name");
  var authorTitle = _.get(author, "fields.title");
  var authorLinkedinUrl = _.get(author, "fields.linkedinUrl");
  var authorImageUrl = _.get(
    _.find(_.get(entries, "includes.Asset"), {
      sys: { id: _.get(author, "fields.profilePicture.sys.id") },
    }),
    "fields.file.url"
  );

  return (
    <div className="blog-post-hero">
      <div className="blog-post-hero-content">
        <div className="blog-post-hero-article">
          <div className="blog-post-hero-content-lhs">
            <div className="blog-post-hero-article-placard">
              <div className="blog-post-hero-article-date">
                Published {dateUtils.getFormattedDate(publishDate)}
              </div>
              <div className="blog-post-hero-title">{articleTitle}</div>
              <div className="blog-post-hero-tags"></div>
              <div className="blog-post-hero-author">
                <img
                  className="blog-post-hero-author-image"
                  src={authorImageUrl}
                />
                <div className="blog-post-hero-author-description">
                  <div className="blog-post-hero-author-name">{authorName}</div>
                  <a
                    className="blog-post-hero-author-title"
                    href={authorLinkedinUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {authorTitle}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="blog-post-hero-content-rhs">
            <img
              className="blog-post-hero-image"
              src={articleFeatureImageUrl + "?fit=thumb&f=top_left&h=207&w=368"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Article({ entries }) {
  var article =
    _.first(_.orderBy(entries.items, "fields.publishDate", "desc")) || {};
  var content = _.get(article, "fields.content");

  const renderOptions = {
    renderNode: {
      ["embedded-asset-block"]: (node) => {
        const asset = _.find(_.get(entries, "includes.Asset"), {
          sys: { id: _.get(node, "data.target.sys.id") },
        });

        const assetUrl = _.get(asset, "fields.file.url");

        return `<img
          src="${assetUrl}"
          class="blog-post-hero-image"
        />`;
      },
    },
  };

  return (
    <div className="blog-post-article">
      <div className="blog-post-article-content">
        <div className="blog-post-article-content-inner">
          <div
            className="blog-post-article-body"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(content, renderOptions),
            }}
          />
          <div className="blog-post-article-rhs">
            <div className="blog-post-article-rhs-inner">
              <Subscribe />
              <Share />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Author({ entries }) {
  const article =
    _.first(_.orderBy(entries.items, "fields.publishDate", "desc")) || {};

  const author =
    _.find(_.get(entries, "includes.Entry"), {
      sys: { id: _.get(article, "fields.author.sys.id") },
    }) || {};
  const authorName = _.get(author, "fields.name");
  const authorTitle = _.get(author, "fields.title");
  const authorDescription = _.get(author, "fields.description");
  const authorLinkedinUrl = _.get(author, "fields.linkedinUrl");
  const authorImageUrl = _.get(
    _.find(_.get(entries, "includes.Asset"), {
      sys: { id: _.get(author, "fields.profilePicture.sys.id") },
    }),
    "fields.file.url"
  );

  return (
    <div className="blog-post-author">
      <div className="blog-post-author-content">
        <div className="blog-post-author-intro">About the Author</div>
        <div className="blog-post-author-container">
          <div className="blog-post-author-left">
            <img src={authorImageUrl} className="blog-post-author-image" />
            <div>
              <div className="blog-post-author-name">{authorName}</div>
              <a
                className="blog-post-author-title"
                href={authorLinkedinUrl}
                target="_blank"
                rel="noreferrer"
              >
                {authorTitle}
              </a>
            </div>
          </div>
          <div className="blog-post-author-description">
            {authorDescription}
          </div>
        </div>
      </div>
    </div>
  );
}

function Share() {
  const url = encodeURI(window.location.href);
  return (
    <div className="blog-post-share">
      <div className="blog-post-share-title">Share</div>
      <div className="blog-post-share-links">
        <a
          className="blog-post-share-link"
          href={`https://twitter.com/intent/tweet?url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SvgX />
        </a>
        <a
          className="blog-post-share-link"
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SvgLinkedIn />
        </a>
      </div>
    </div>
  );
}

function Subscribe() {
  var [email, setEmail] = React.useState("");
  var [consent, setConsent] = React.useState(false);
  var [subscribed, setSubscribed] = React.useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    if (!consent || !email) return;
    var form = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
      ],
      context: {
        pageUri: window.location.href,
      },
      legalConsentOptions: {
        consent: {
          // Include this object when GDPR options are enabled
          consentToProcess: consent,
          text: "I agree to the Terms of Service and Privacy Policy",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: "I agree to the Terms of Service and Privacy Policy",
            },
          ],
        },
      },
    };
    if (email) {
      return hubspotActions
        .submitToForm({
          formId: HUBSPOT_FORM_ID,
          form: form,
        })
        .then(() => {
          setSubscribed(true);
        });
    }
  }

  if (subscribed) {
    return (
      <div className="blog-post-subscribe">
        <div className="blog-post-subscribe-form" onSubmit={handleSubmit}>
          <div className="blog-post-subscribe-title">Stay Connected</div>
          <div className="blog-post-subscribe-description">
            Thanks for subscribing!
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="blog-post-subscribe">
      <form className="blog-post-subscribe-form" onSubmit={handleSubmit}>
        <div className="blog-post-subscribe-title">Stay Connected</div>
        <div className="blog-post-subscribe-description">
          Subscribe to receive new blog posts from Prelim.
        </div>
        <input
          className="blog-post-subscribe-email"
          placeholder="Enter your work email"
          type="text"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="checkbox"
          className="blog-post-subscribe-consent"
          onChange={(e) => setConsent(e.target.checked)}
          id="checkbox-consent"
        />
        <label
          className="blog-post-subscribe-consent-label"
          htmlFor="checkbox-consent"
        >
          I agree to the{" "}
          <a href="legal/terms-of-service" className="blog-post-subscribe-link">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="legal/privacy" className="blog-post-subscribe-link">
            Privacy Policy
          </a>
        </label>
        <button
          className="blog-post-subscribe-button"
          type="Submit"
          onClick={handleSubmit}
        >
          Subscribe
        </button>
      </form>
    </div>
  );
}

function BlogPost({ match }) {
  const slug = match.params.slug;
  var [entries, setEntries] = React.useState({});
  React.useEffect(() => {
    contentfulActions
      .getEntries({ content_type: "blogPost", "fields.slug": slug })
      .then((res) => {
        setEntries(res);
      });
  }, []);

  if (_.isEmpty(entries)) {
    return <></>;
  }

  const article =
    _.first(_.orderBy(entries.items, "fields.publishDate", "desc")) || {};

  const articleFeatureImageUrl = _.get(
    _.find(_.get(entries, "includes.Asset"), {
      sys: { id: _.get(article, "fields.featureImage.sys.id") },
    }),
    "fields.file.url"
  );

  const articleTitle = _.get(article, "fields.title");

  const description =
    "The Prelim Blog is a source of news on banking and how to navigate the digitization of banking.";

  return (
    <div>
      <HelmetHeader
        ogImageOverride={articleFeatureImageUrl}
        title={articleTitle}
        description={description}
      />
      <Header />
      <Hero entries={entries} />
      <Article entries={entries} />
      <Author entries={entries} />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default withRouter(BlogPost);
