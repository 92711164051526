import React from "react";
import "../css/call_to_action.css";
import SvgArrowForward from "./svg_arrow_forward";

function CallToAction() {
  var [email, setEmail] = React.useState("");

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    window.location.href = `/signup?email=${email}`;
  }

  return (
    <div className="call-to-action">
      <div className="call-to-action-content">
        <div className="call-to-action-coa-title">
          See what Prelim can do for you.
        </div>
        <div className="call-to-action-coa-subtitle">
          Prelim works for banks of all shapes and sizes—book a demo and see how
          you can modernize your banking today.
        </div>
        <form className="call-to-action-coa-form" onSubmit={handleSubmit}>
          <input
            className="call-to-action-coa-email"
            placeholder="Enter your work email"
            type="text"
            onChange={handleChange}
          />
          <button
            className="call-to-action-coa-button"
            type="Submit"
            onClick={handleSubmit}
          >
            <SvgArrowForward className="call-to-action-arrow" />
          </button>
        </form>
      </div>
    </div>
  );
}

export default CallToAction;
