import React from "react";
import { Helmet } from "react-helmet";
import FaviconPng from "../pngs/favicon.png";
import ogImage from "../img/og_image.png";

function HelmetHeader({
  title,
  description,
  type = "website",
  ogImageOverride,
}) {
  if (!title) return <></>;

  const metaOgImage = ogImageOverride || ogImage;

  const url = window.location.href;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="title" content={title} />

      <link rel="icon" href={FaviconPng} />
      <link rel="apple-touch-icon" href={FaviconPng} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={metaOgImage} name="image" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="prelim.com" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={metaOgImage} />
    </Helmet>
  );
}

export default HelmetHeader;
