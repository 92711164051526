// import _ from "lodash";
import React from "react";
import "../css/landing.css";
import SvgExpandArrow from "./svg_expand_arrow";

function Accordion({ title, content, active, toggle }) {
  // var [isActive, setIsActive] = React.useState(active);
  return (
    <div key={title} className="landing-omnichannel-accordion-item">
      {active && <div className="landing-omnichannel-accordion-sidebar" />}
      <div
        className="landing-omnichannel-accordion-header"
        onClick={() => toggle(title)}
      >
        <div
          className={
            active
              ? "landing-omnichannel-accordion-title-expanded"
              : "landing-omnichannel-accordion-title"
          }
        >
          {title}
        </div>
        <SvgExpandArrow className="landing-omnichannel-accordion-button" />
      </div>
      {active && (
        <div className="landing-omnichannel-accordion-content">{content}</div>
      )}
    </div>
  );
}

export default Accordion;
