import _ from "lodash";
import React from "react";
import contentfulActions from "../actions/contentful";
import "../css/consumer_account_opening.css";
import AnalysisPreview from "../img/analysis_preview.png";
import DesktopBankerTable from "../img/desktop_banker_table.png";
import DesktopMCBNYStart from "../img/desktop_mcbny_start.png";
import LandingVideoThumbnail from "../img/landing_vid_thumbnail.jpg";
import LogoPacificWestern from "../img/logo_pacific_western.png";
import MobileMCBNYStart from "../img/mobile_mcbny_start.png";
import MobileTDBComplete from "../img/mobile_tdb_complete.png";
import MobileTDBStart from "../img/mobile_tdb_start.png";
import ProfileSusanTang from "../img/profile_susan_tang.png";
import TabletMCBNYStart from "../img/tablet_mcbny_start.png";
import CallToAction from "./call_to_action";
import Footer from "./footer";
import Header from "./header";
import HelmetHeader from "./helmetHeader";
import Lightbox from "./lightbox";
import NewsCallout from "./news_callout";
import SvgConsumerAccountOpeningBubble from "./svg_consumer_account_opening_bubble";
import SvgPlayArrow from "./svg_play_arrow";
import Testimonial from "./testimonial";

function Customers() {
  var [entries, setEntries] = React.useState({});
  React.useEffect(() => {
    contentfulActions.getEntries({ content_type: "customer" }).then((res) => {
      setEntries(res);
    });
  }, []);

  const orderedEntries = _.orderBy(entries.items, "fields.order");

  const tripledEntries = _.concat(
    orderedEntries,
    orderedEntries,
    orderedEntries
  );

  return (
    <div className="consumer-account-opening-customers">
      <div className="consumer-account-opening-customers-content">
        <div className="consumer-account-opening-customers-list">
          {_.map(tripledEntries, (entry) => {
            const imageUrl = _.get(
              _.find(_.get(entries, "includes.Asset"), {
                sys: { id: _.get(entry, "fields.image.sys.id") },
              }),
              "fields.file.url"
            );

            return (
              <div
                className="consumer-account-opening-customer"
                key={entry.sys.id}
              >
                <div className="consumer-account-opening-customers-image">
                  <img
                    src={imageUrl}
                    className="consumer-account-opening-customers-logo-img"
                    alt={entry.fields.name}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function EndToEnd() {
  return (
    <div className="consumer-account-opening-end-to-end">
      <div className="consumer-account-opening-end-to-end-content">
        <div className="consumer-account-opening-end-to-end-title">
          End to end account opening
        </div>
      </div>
      <div className="consumer-account-opening-end-to-end-content">
        <div className="consumer-account-opening-end-to-end-content-bottom">
          <div className="consumer-account-opening-end-to-end-lhs">
            <div className="consumer-account-opening-end-to-end-copy">
              <div className="consumer-account-opening-end-to-end-category">
                From digital application to core
              </div>
              <div className="consumer-account-opening-end-to-end-description-container">
                <div className="consumer-account-opening-end-to-end-description">
                  Fast-to-market digital customer acquisition journeys through a
                  pre-built solution that runs on the Prelim platform. The
                  extensibility of the platform provides agility to respond to
                  market changes, stay competitive, and connect to the latest
                  innovations in account opening experiences.
                </div>
              </div>
            </div>
          </div>
          <div className="consumer-account-opening-end-to-end-rhs">
            <div className="consumer-account-opening-end-to-end-images">
              <img
                src={MobileTDBStart}
                className="consumer-account-opening-end-to-end-image-left"
              />
              <img
                src={MobileTDBComplete}
                className="consumer-account-opening-end-to-end-image-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WorkWith() {
  return (
    <div className="consumer-account-opening-work-with">
      <div className="consumer-account-opening-work-with-content">
        <div className="consumer-account-opening-work-with-title">
          Work with customers in real time
        </div>
      </div>
      <div className="consumer-account-opening-work-with-content">
        <div className="consumer-account-opening-work-with-content-bottom">
          <div className="consumer-account-opening-work-with-lhs">
            <img
              src={DesktopBankerTable}
              className="consumer-account-opening-work-with-image"
            />
          </div>
          <div className="consumer-account-opening-work-with-rhs">
            <div className="consumer-account-opening-work-with-content-inner">
              <div className="consumer-account-opening-work-with-category">
                Banker portal
              </div>
              <div className="consumer-account-opening-work-with-description-container">
                <div className="consumer-account-opening-work-with-description">
                  {`Prelim’s launchpad enables the bank to use an out of the box customer application, enabling the bank to go live in weeks, not years. Prelim’s platform tailors customer journeys to the brand and image of the bank.`}
                  <br />
                  <br />
                  {`Prelim’s builder platform enables the changing of questions, sections, and visual design without sacrificing speed to the market.`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Omnichannel() {
  return (
    <div className="consumer-account-opening-omnichannel">
      <div className="consumer-account-opening-omnichannel-content">
        <div className="consumer-account-opening-omnichannel-title">
          Onboarding in any channel: online, in branch, phone, computer
        </div>
      </div>
      <div className="consumer-account-opening-omnichannel-content-container">
        <div className="consumer-account-opening-omnichannel-content">
          <div className="consumer-account-opening-omnichannel-content-top">
            <div className="consumer-account-opening-omnichannel-lhs">
              <img
                src={TabletMCBNYStart}
                className="consumer-account-opening-omnichannel-tablet"
              />
            </div>
            <div className="consumer-account-opening-omnichannel-rhs">
              <div className="consumer-account-opening-omnichannel-copy">
                <div className="consumer-account-opening-omnichannel-category">
                  Uniform customer experience
                </div>
                <div className="consumer-account-opening-omnichannel-description-container">
                  <div className="consumer-account-opening-omnichannel-description">
                    Prelim is used by banks to deliver an amazing customer
                    experience on mobile, tablet, laptop, desktop; online or in
                    the branch.
                    <br />
                    <br />
                    Prelim enables bank customers to complete either
                    individually or jointly, only showing the relevant sections
                    to each customer. Bankers are able to copilot the customers
                    experience, guiding the customers journey.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="consumer-account-opening-omnichannel-content-bottom-container">
            <div className="consumer-account-opening-omnichannel-content-bottom">
              <div>
                <img
                  src={DesktopMCBNYStart}
                  className="consumer-account-opening-omnichannel-desktop"
                />
              </div>
              <div>
                <img
                  src={MobileMCBNYStart}
                  className="consumer-account-opening-omnichannel-mobile"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Hero() {
  var [email, setEmail] = React.useState("");
  var [modalIsOpen, setModalIsOpen] = React.useState(false);
  function handleChange(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    window.location.href = `/signup?email=${email}`;
  }

  const handleClickVideo = () => {
    setModalIsOpen(true);
  };

  const videoUrl = "https://player.vimeo.com/video/893464397?h=725dde136b";
  return (
    <div className="consumer-account-opening-hero">
      <Lightbox
        videoUrl={videoUrl}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        closeModal={() => setModalIsOpen(false)}
      />
      <SvgConsumerAccountOpeningBubble className="consumer-account-opening-hero-bubble" />
      <div className="consumer-account-opening-hero-content">
        <div className="consumer-account-opening-hero-title">
          Consumer Deposit Account Opening
        </div>
        <div className="consumer-account-opening-hero-container">
          <div className="consumer-account-opening-hero-container-lhs">
            <div className="consumer-account-opening-hero-copy-container">
              <div className="consumer-account-opening-hero-subtitle">
                It starts with Prelim
              </div>
              <div className="consumer-account-opening-hero-description">
                Increase speed-to-market, address compliance and risk, and
                improve the experience for consumers.
              </div>
            </div>
            <div className="consumer-account-opening-hero-form-container">
              <form
                className="consumer-account-opening-hero-form"
                onSubmit={handleSubmit}
              >
                <input
                  className="consumer-account-opening-hero-email-input"
                  placeholder="Enter your work email"
                  type="text"
                  onChange={handleChange}
                />
                <button
                  className="consumer-account-opening-hero-button"
                  type="Submit"
                  onClick={handleSubmit}
                >
                  Talk to an expert
                </button>
              </form>
            </div>
          </div>
          <div
            className="consumer-account-opening-hero-video"
            onClick={handleClickVideo}
          >
            <img
              className="consumer-account-opening-hero-video-thumbnail"
              src={LandingVideoThumbnail}
            />
            <div className="consumer-account-opening-hero-video-button" />
            <div className="consumer-account-opening-hero-video-button-inner" />
            <SvgPlayArrow className="consumer-account-opening-hero-button-image" />
          </div>
        </div>
      </div>
    </div>
  );
}

function MetCapTestimonial({ ready }) {
  if (!ready) return null;
  const quote =
    "Prelim powers the next generation of digital transformation and growth at our bank.";
  const name = "Susan Tang";
  const title = "EVP, Head of Treasury Sales & Product at Pacific Western Bank";
  return (
    <Testimonial
      quote={quote}
      logoImg={LogoPacificWestern}
      name={name}
      title={title}
      quoteeImg={ProfileSusanTang}
    />
  );
}

function Analysis() {
  return (
    <div className="consumer-account-opening-analysis">
      <div className="consumer-account-opening-analysis-content">
        <div className="consumer-account-opening-analysis-title">
          Analyze and discover improvements
        </div>
      </div>
      <div className="consumer-account-opening-analysis-content">
        <div className="consumer-account-opening-analysis-content-bottom">
          <div className="consumer-account-opening-analysis-content-lhs">
            <div className="consumer-account-opening-analysis-image-container">
              <img
                src={AnalysisPreview}
                className="consumer-account-opening-analysis-image"
              />
            </div>
          </div>
          <div className="consumer-account-opening-analysis-content-rhs">
            <div className="consumer-account-opening-analysis-content-copy">
              <div className="consumer-account-opening-analysis-content-title">
                Insights & Discovery Dashboard
              </div>
              <div className="consumer-account-opening-analysis-content-description">
                Detailed behavioral analytics from Prelim Insights help you
                understand how customers interact with the journey, far beyond
                anything offered in other packaged solutions. In addition,
                receive an annual CX best practice review, specific to your
                application.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ConsumerAccountOpening() {
  var title = "Prelim: The platform for consumer deposit account opening.";
  var description =
    "Prelim is the #1 platform for financial institutions to work with customers digitally. Go digital with zero code using one white-labeled, all-in one platform that integrates with your current technology stack.";
  return (
    <div>
      <HelmetHeader title={title} description={description} />
      <Header />
      <Hero />
      <Customers />
      <EndToEnd />
      <WorkWith />
      <Omnichannel />
      <MetCapTestimonial />
      <Analysis />
      <NewsCallout />
      <CallToAction />
      <Footer />
    </div>
  );
}

export default ConsumerAccountOpening;
