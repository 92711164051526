import React from "react";

const Svg = (props) => (
  <svg
    width="947"
    height="484"
    viewBox="0 0 947 484"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M141.042 410.938C49.0141 393.062 -1.10488 297.202 5.64044 235.184C274.361 202.326 882.663 137.894 888.791 159.885C896.451 187.374 761.52 405.257 532.001 352.202C395.822 320.722 278.981 437.733 141.042 410.938Z"
      fill="url(#paint0_linear_2058_5111)"
    />
    <g filter="url(#filter0_f_2058_5111)">
      <path
        d="M666.31 361.012C930.112 357.381 943.77 120.824 917.623 3L444.375 181.813C408.436 243.059 402.508 364.644 666.31 361.012Z"
        fill="url(#paint1_linear_2058_5111)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2058_5111"
        x="423.506"
        y="0"
        width="506.375"
        height="364.092"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1.5"
          result="effect1_foregroundBlur_2058_5111"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2058_5111"
        x1="330.844"
        y1="640.365"
        x2="296.52"
        y2="198.045"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9789" />
        <stop offset="1" stopColor="#FF9789" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2058_5111"
        x1="790.774"
        y1="438.024"
        x2="556.793"
        y2="124.716"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2037DC" />
        <stop offset="0.708333" stopColor="#2037DC" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
