import React from "react";
import Modal from "react-modal";
import "../css/lightbox.css";
import SvgClose from "./svg_close";

function Lightbox({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  videoUrl,
  setModalIsOpen,
}) {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="lightbox-modal"
        shouldCloseOnOverlayClick={true}
        overlayClassName="lightbox-overlay"
      >
        <div className="lightbox-close">
          <SvgClose
            className="lightbox-close-icon"
            fill="white"
            onClick={() => setModalIsOpen(false)}
          />
        </div>
        <iframe
          width="770px"
          height="433px"
          className="lightbox-video"
          src={videoUrl}
          title={"Prelim Explainer"}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </Modal>
    </>
  );
}
export default Lightbox;
