const SPACE_ID = "wcmp7p96knlv";
const ACCESS_TOKEN = "4doLz22q-sSlUnFW7ERzOTfC4SfdTZlCV1-c9dtG1Q0";
const ROOT_URL = "https://cdn.contentful.com/spaces/" + SPACE_ID;

function getEntries(query) {
  const searchParams = new URLSearchParams(
    Object.assign(
      {
        access_token: ACCESS_TOKEN,
      },
      query
    )
  );
  return fetch(ROOT_URL + `/entries?${searchParams.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function getEntry({ id }) {
  return fetch(ROOT_URL + `/entries/${id}?access_token=${ACCESS_TOKEN}`, {
    method: "GET",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

module.exports = {
  getEntries,
  getEntry,
};
