import React from "react";
import Landing from "./landing";
import Integrations from "./integrations";
import Signup from "./signup";
import Careers from "./careers";
import Event from "./event";
import BusinessAccountOpening from "./business_account_opening";
import ConsumerAccountOpening from "./consumer_account_opening";
import About from "./about";
import Blog from "./blog";
import BlogPost from "./blog_post";
import News from "./news";
import CustomerStories from "./customer_stories";
import NotFound from "./not_found";
import Security from "./security";
import Banner from "./banner";
import TermsOfService from "./terms_of_service";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Banner />
      <Switch>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/careers">
          <Careers />
        </Route>
        <Route exact path="/integrations">
          <Integrations />
        </Route>
        <Route exact path="/event/webinar-march-25-2022">
          <Event />
        </Route>
        <Route exact path="/business-account-opening">
          <BusinessAccountOpening />
        </Route>
        <Route exact path="/consumer-account-opening">
          <ConsumerAccountOpening />
        </Route>
        <Route exact path="/company">
          <About />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/customer-stories">
          <CustomerStories />
        </Route>
        <Route exact path="/news">
          <News />
        </Route>
        <Route exact path="/security">
          <Security />
        </Route>
        <Route path="/blog/:slug">
          <BlogPost />
        </Route>
        <Route exact path="/blog">
          <Blog />
        </Route>
        <Route exact path="/legal/terms-of-service">
          <TermsOfService />
        </Route>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="/*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
