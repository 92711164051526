import React from "react";

const Svg = (props) => (
  <svg
    width="1290"
    height="375"
    viewBox="0 0 1290 375"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 374.2C134.953 112.796 440.499 90.7269 703.867 244.954L1270 141C1230 240.026 1057 504.268 784.5 298.775C758.239 278.972 731.282 261.008 703.867 244.954L0 374.2Z"
      fill="url(#paint0_linear_2065_5113)"
    />
    <path
      d="M776.983 159.268C892.024 40.5319 1156.17 -105.21 1292.42 261.711L776.983 159.268Z"
      fill="url(#paint1_linear_2065_5113)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2065_5113"
        x1="635"
        y1="-29.0895"
        x2="715.997"
        y2="366.316"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9F3A9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2065_5113"
        x1="1076.75"
        y1="-48.4789"
        x2="1011.61"
        y2="217.026"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7280E4" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
