import React from "react";

const Svg = (props) => (
  <svg
    width="1440"
    height="721"
    viewBox="0 0 1440 721"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M305.583 112.018C262.423 271.61 101.882 527.03 -195 271.968L305.583 112.018Z"
      fill="url(#paint0_linear_2083_5197)"
    />
    <g filter="url(#filter0_f_2083_5197)">
      <path
        d="M776.673 648.308C845.98 816.172 61.8781 455.017 -268.44 486.229L278.421 288.158C894.22 69.136 700.243 463.193 776.673 648.308Z"
        fill="url(#paint1_linear_2083_5197)"
      />
    </g>
    <g filter="url(#filter1_f_2083_5197)">
      <path
        d="M927.435 252.803C901.24 192.825 900.375 118.623 1012.33 58.0229L1885.17 341.218C1804.32 377.67 1170.74 260.355 927.435 252.803C962.988 334.212 1045.2 389.417 955.323 348.842C783.344 271.203 809.682 249.148 927.435 252.803Z"
        fill="url(#paint2_linear_2083_5197)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2083_5197"
        x="-272.441"
        y="219.663"
        width="1057.43"
        height="477.354"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2083_5197"
        />
      </filter>
      <filter
        id="filter1_f_2083_5197"
        x="828.729"
        y="54.0229"
        width="1060.44"
        height="312.462"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="2"
          result="effect1_foregroundBlur_2083_5197"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2083_5197"
        x1="143.894"
        y1="438.94"
        x2="72.3325"
        y2="175.091"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7280E4" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2083_5197"
        x1="767.336"
        y1="196.751"
        x2="735.768"
        y2="467.513"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#FF9789" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2083_5197"
        x1="760.935"
        y1="421.912"
        x2="908.284"
        y2="44.4449"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0378586" stopColor="#E5FF45" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Svg;
